import moment from "moment";
import React, { useState, useEffect } from "react";
import Step from "@mui/material/Step";
import Stack from "@mui/material/Stack";
import { useSelector } from "react-redux";
import Stepper from "@mui/material/Stepper";
import { styled } from "@mui/material/styles";
import { Grid, Typography, Box } from "@mui/material";
import { Theme } from "../../../../Styles/GlobalTheme";
import { StepIconProps } from "@mui/material/StepIcon";
import warningIcon from "../../../../assets/icons/Warning.svg";
import GoIconGray from "../../../../assets/icons/GoIconGray.svg";
import StepLabel, { stepLabelClasses } from "@mui/material/StepLabel";
import hourGlassIcon from "../../../../assets/icons/hourGlassIcon.svg";
import OrderIconBlue from "../../../../assets/icons/OrderIconBlue.svg";
import { ManageAccordion } from "../TroubleTicketStatus/ManageAccordion";
import { ImappingData } from "../TroubleTicketStatus/TroubleTicketStatus";
import { Spinner } from "../../../../components/shared/LoadingSpinner/Spinner";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import { StepperBox, TrackOrderBox, CardHeading, StyledA } from "../../../../Styles/GlobalStyles";
import {
  CANCELLED_STATUS,
  COMMIT_FAILED,
  FULLFILLED,
  INSTALL,
  ORDER,
} from "../../../../Constants/constants";
import { addSpaceAfterComma, formatPhoneNumber, getFlags } from "../../../../Services/helper";

const steps = ["Order", "Install", "Go"];

export const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "white",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "white",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 2,
    border: `2px dashed ${Theme.palette.customcolor.whiteColor}`,
  },
}));

export const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor: Theme.palette.customcolor.whiteColor,
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    border: `4px solid ${Theme.palette.customcolor.lightGreen}`,
    boxShadow: `1px 1px 30px 4px ${Theme.palette.customcolor.whiteColor}`,
  }),
  ...(ownerState.completed && {
    backgroundColor: "none",
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;
  const icons: { [index: string]: React.ReactElement } = {
    1: <img src={OrderIconBlue} alt="order" width="40" height="150" />,
    2: <img src={hourGlassIcon} alt="hourGlass" width="40" height="150" />,
    3: <img src={GoIconGray} alt="go" width="40" height="150" />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

export const ColorlibStepLabel = styled(StepLabel)(() => ({
  [`& .${stepLabelClasses.label}`]: {
    [`&.${stepLabelClasses.completed}`]: {
      fontWeight: 600,
      color: Theme.palette.customcolor.whiteColor,
    },
    [`&.${stepLabelClasses.active}`]: {
      fontWeight: 600,
      color: Theme.palette.customcolor.whiteColor,
    },
    color: Theme.palette.customcolor.whiteColor,
  },
}));

export const TrackOrder = (props: any) => {
  const { orderDetailsLoading } = props;
  const [activeStep, setActiveStep] = useState<any>(0);
  const envFlags = useSelector((state: any) => state?.userInfo?.envFlags);
  const SHOW_WHERE_IS_MY_TECH = getFlags("enable_where_is_my_tech", envFlags);
  const { orderDetails, orderInstallationDateTime } = useSelector(
    (state: any) => state.customerInfoDetails
  );
  const { serviceAddress } = useSelector((state: any) => state.customerPlanDetails);

  const orderStatus = orderDetails?.orderStatus;

  const isAppointment =
    orderDetails?.installationAppointment && orderDetails?.installationAppointmentEndDate;

  const handleActiveStep = () => {
    switch (orderStatus) {
      case FULLFILLED:
        setActiveStep(2);
        break;
      case ORDER:
        setActiveStep(0);
        break;
      case INSTALL:
        setActiveStep(1);
        break;
      default:
        setActiveStep(0);
        break;
    }
  };
  const inputData = moment.utc(orderDetails?.installationAppointment)?.format("MMM DD");
  const mappingData: ImappingData[] = [
    {
      name: "Order number :",
      value: `${orderDetails?.orderId}`,
    },
    ...(orderDetails?.product || orderDetails?.equipment || orderDetails?.phone
      ? [
          {
            name: "Order items",
            value: (
              <>
                {orderDetails?.product && (
                  <>
                    Plan: {orderDetails?.product}
                    <br />
                  </>
                )}
                {orderDetails?.equipment && <>Add ons: {orderDetails?.equipment}</>}
                {orderDetails?.phone && <>,{orderDetails?.phone}</>}
              </>
            ),
          },
        ]
      : []),
    {
      name: "Installation appointment :",
      value:
        isAppointment &&
        ` ${inputData}, ${orderInstallationDateTime?.installStart} - ${orderInstallationDateTime?.installEnd}`,
    },
    {
      name: "Service address :",
      value: addSpaceAfterComma(serviceAddress),
    },
    ...(SHOW_WHERE_IS_MY_TECH
      ? [
          {
            name: "Estimated Tech Arrival Time :",
            value: orderDetails?.estimatedArrivalTime,
          },
          {
            name: "Estimated Work Duration :",
            value: orderDetails?.estimatedWorkDuration,
          },
          {
            name: "Technician Name :",
            value: orderDetails?.fieldTechnicianName,
          },
          {
            name: "Technician Contact :",
            value: formatPhoneNumber(orderDetails?.fieldTechnicianContact),
          },
        ]
      : []),
  ];

  useEffect(() => {
    handleActiveStep();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderStatus]);

  return (
    <>
      <CardHeading>Track your order</CardHeading>
      <TrackOrderBox>
        {orderDetailsLoading ? (
          <Box py={10}>
            <Spinner />
          </Box>
        ) : (
          <>
            <StepperBox
              sx={{
                backgroundColor:
                  orderDetails?.orderStatus?.orders === null
                    ? "none"
                    : Theme.palette.secondary.main,
                borderRadius: orderDetails?.orderStatus === COMMIT_FAILED ? 2 : "none",
              }}>
              {orderDetails?.orderStatus === COMMIT_FAILED ? (
                <>
                  <Grid container justifyContent={"space-between"}>
                    <Grid item>
                      <Typography
                        color={"white"}
                        fontWeight={600}
                        component={"p"}
                        fontSize="large"
                        data-testid="status">
                        {orderDetails?.orderStatusMessage}
                      </Typography>
                      <Typography
                        component={"p"}
                        color={"white"}
                        variant="body2"
                        data-testid="message">
                        Order number&nbsp;{orderDetails?.orderId}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Stack justifyContent={"center"} alignItems={"center"} my={1}>
                    <img src={warningIcon} alt="warning" height={60} />
                    <Typography color={Theme.palette.customcolor.whiteColor} textAlign={"center"}>
                      The system couldn't process your reschedule request. We've forwarded it to our
                      support team.
                    </Typography>
                    <Typography
                      color={Theme.palette.customcolor.whiteColor}
                      textAlign={"center"}
                      component={"div"}
                      fontSize={{ xs: "14px", md: "16px" }}>
                      For any questions, please contact us at :&nbsp;
                      <StyledA href="tel:1-866-699-4759">1.866.699.4759</StyledA>
                    </Typography>
                  </Stack>
                </>
              ) : (
                <>
                  <Grid container justifyContent={"space-between"}>
                    <Grid item>
                      <Typography
                        color={"white"}
                        fontWeight={600}
                        component={"p"}
                        fontSize="large"
                        data-testid="status">
                        {orderDetails?.orderStatusMessage}
                      </Typography>
                      <Typography
                        component={"p"}
                        color={"white"}
                        variant="body2"
                        data-testid="message">
                        Order number&nbsp;{orderDetails?.orderId}
                      </Typography>
                    </Grid>
                  </Grid>
                  {orderStatus !== CANCELLED_STATUS && (
                    <Stack py={3}>
                      <Stepper
                        alternativeLabel
                        activeStep={activeStep}
                        connector={<ColorlibConnector />}>
                        {steps?.map((label, index) => (
                          <Step key={index}>
                            <ColorlibStepLabel StepIconComponent={ColorlibStepIcon}>
                              {label}
                            </ColorlibStepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </Stack>
                  )}
                </>
              )}
            </StepperBox>
            {orderDetails?.orderStatus !== COMMIT_FAILED && (
              <ManageAccordion title={orderDetails?.headerTitle ?? ""} data={mappingData} />
            )}
          </>
        )}
      </TrackOrderBox>
    </>
  );
};

import { useState, useEffect } from "react";
import { Box, Typography, Stack, Card, styled } from "@mui/material";
import Timeline from "@mui/lab/Timeline";
import { useSelector, useDispatch } from "react-redux";
import { timelineItemClasses } from "@mui/lab/TimelineItem";
import routerIcon from "../../../../assets/icons/Router Connection New.svg";
import ExpandableSectionHeader from "./ExpandableSectionHeader";
import speedTestIcon from "../../../../assets/icons/Speed test New.svg";
import {
  AccountDivider,
  CardHeading,
  CenterStack,
  ColumnCenterStack,
  StyleAccordian,
  StyleAccordionSummary,
  StyledA,
  StyledCard,
  StyledElement,
} from "../../../../Styles/GlobalStyles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import networkConnectionIcon from "../../../../assets/icons/Connection Quality New.svg";
import { useCheckRouterType } from "../../../../hooks/networkStatus/checkRouterType";
import {
  BREAK_LEASE_FAILED,
  BREAK_LEASE_SUCCESSFUL,
  INTERNET_SPEED_TEST,
  CONNECTION_QUALITY_TEST,
  REBOOT_ROUTER,
  REBOOT_ERROR,
  ROUTER_CONNECTION_TEST,
  CONNECTION_QUALITY_SUBTEXT,
  SPEED_TEST_SUBTEXT,
  ROUTER_CONNECTION_SUBTEXT,
  CONNECTION_QUALITY_TEST_MESSAGE,
  CONNECTION_QUALITY_TEST_SUB_MESSAGE,
  ROUTER_CONNECTION_MESSAGE,
  STATUS_ACTIVE,
  STATUS_OK,
  OUTAGE_MESSAGE,
  ROUTER_CONNECTION_INACTIVE_TEXT,
  NETWORK_CONNECTION_INACTIVE_TEXT,
  ROUTER_CONNECTION_HERE_LINK,
} from "../../../../Constants/constants";
import { useRGReboot } from "../../../../hooks/networkStatus/useRGReboot";
import { Theme } from "../../../../Styles/GlobalTheme";
import { PANEL_1 } from "../../../../Constants/constants";
import Button from "../../../../components/Button/Button";
import { useBreakLease } from "../../../../hooks/networkStatus/useBreakLease";
import {
  setBreakLeaseApiError,
  setBreakLeaseSuccess,
  setRebootRGApiError,
  setRGStatusApiError,
  setRGStatusApiErrorMsg,
  setTestConnectionApiError,
  setTestConnectionApiErrorMsg,
} from "../../../../Redux/Slices/NetworkStatusSlice";
import LinearDeterminateProgress from "../../../../components/shared/LinearDeterminateProgress/LinearDeterminateProgress";
import SuccessFailureDialog from "./SuccessFailureDialog";
import ContactSupport from "../../../../components/shared/ContactSupport/ContactSupport";
import SuccessDialogBox from "./SuccessDialogBox";
import { getFlags, splitMessageBybraces } from "../../../../Services/helper";
import {
  setExistingTicket,
  setOpenOutageBanner,
  setOpenSelectReasons,
  setOpenServiceTicketDialog,
} from "../../../../Redux/Slices/TroubleTicketSlice";
import { Spinner } from "../../../../components/shared/LoadingSpinner/Spinner";
import OutageBanner from "./OutageBanner";

const NetworkStatus = (props: any) => {
  const dispatch = useDispatch();
  const { outageDetailsLoading, showNetworkStatus, showOutage } = props;
  const {
    networkConnectionDetails,
    testConnectionApiError,
    RGStatusDetails,
    RGStatusApiError,
    breakLeaseMessage,
    testConnectionApiErrorMsg,
    RGStatusApiErrorMsg,
    breakLeaseApiError,
    breakLeaseSuccess,
    rebootRGApiError,
    enableTestYourConnection,
    enableRgStatus,
    supportsSpeedTest,
    enableBreakLease,
    checkRouterTypeMessage,
  } = useSelector((state: any) => state.networkStatusDetails);
  const { accountNumber } = useSelector((state: any) => state.customerInfoDetails);
  const { troubleTicketDetails } = useSelector((state: any) => state.troubleTicketDetails);
  const [accountNum, setAccountNum] = useState("");
  const { billingAddress } = useSelector((state: any) => state.customerPlanDetails);
  const [expanded, setExpanded] = useState<string | false>(false);
  const { checkRouterTypeLoading } = useCheckRouterType();
  const { handleRebootRG, rebootLoading } = useRGReboot();
  const { handleBreakLease, breakLeaseLoading } = useBreakLease();
  const [networkConnectionStatusMsg, setNetworkConnectionStatusMsg] = useState<string>("");
  const [networkConnectionStatusSubMsg, setNetworkConnectionStatusSubMsg] = useState<any>("");
  const [routerConnectionStatus, setRouterConnectionStatus] = useState("");
  const [ConnectionTestSupportingText, setConnectionTestSupportingText] = useState<any>("");
  const [routerTestSupportingText, setRouterTestSupportingText] = useState<any>("");
  const envFlags = useSelector((state: any) => state?.userInfo?.envFlags);
  const SHOW_CREATE_SERVICE_TICKET = getFlags("enable_create_service_ticket", envFlags);
  const networkDetailsNotNull =
    networkConnectionDetails && Object.keys(networkConnectionDetails)?.length > 0;

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };
  const onCloseSuccessDialog = () => {
    dispatch(setBreakLeaseSuccess(false));
  };
  const onCloseFailureDialog = () => {
    dispatch(setBreakLeaseApiError(false));
  };
  const onCloseRebootFailure = () => {
    dispatch(setRebootRGApiError(false));
  };
  const networkConnectionTestData = [
    {
      name: "DEVICE ID",
      value: networkConnectionDetails?.deviceId ?? "",
    },
    {
      name: "MODEL",
      value: networkConnectionDetails?.model ?? "",
    },
    {
      name: "SERIAL",
      value: networkConnectionDetails?.serialNumber ?? "",
    },
    {
      name: "MANUFACTURER",
      value: networkConnectionDetails?.manufacturer?.toUpperCase() ?? "",
    },
    {
      name: "IP ADDRESS",
      value: networkConnectionDetails?.ipAddress ?? "",
    },
    {
      name: "STATUS",
      value: networkConnectionDetails?.testStatus ?? "",
    },
    {
      name: "SERVICE ADDRESS",
      value: networkDetailsNotNull ? billingAddress : "",
    },
  ];

  const homeRouterData = [
    {
      name: "MODEL",
      value: RGStatusDetails?.model ?? "",
    },
    {
      name: "UNIT ADDRESS",
      value: RGStatusDetails?.unitAddress ?? "",
    },
    {
      name: "ID",
      value: RGStatusDetails?.id ?? "",
    },
    {
      name: "WAN IP ADDRESS",
      value: RGStatusDetails?.wanIpAddress ?? "",
    },
    {
      name: "MAC ADDRESS",
      value: RGStatusDetails?.macAddress ?? "",
    },
    {
      name: "ROUTER STATUS",
      value: RGStatusDetails?.rgStatus ?? "",
    },
  ];
  useEffect(() => {
    dispatch(setTestConnectionApiError(false));
    dispatch(setTestConnectionApiErrorMsg(""));
    dispatch(setRGStatusApiError(false));
    dispatch(setRGStatusApiErrorMsg(""));
  }, []);

  const handleReportServiceTicket = () => {
    dispatch(setOpenServiceTicketDialog(true));
    if (troubleTicketDetails?.tickets?.length) {
      dispatch(setExistingTicket(true));
      return;
    }
    if (showOutage) {
      dispatch(setOpenOutageBanner(true));
    }
  };

  const status = networkConnectionDetails?.testStatus?.toLowerCase();
  const routerConnectionSubText = (
    <ColumnCenterStack gap={0} my={1}>
      <CenterStack direction={"row"} flexWrap={"wrap"} gap={1}>
        {SHOW_CREATE_SERVICE_TICKET ? (
          <>
            <StyledElement fontWeight={600} fontSize={"14px"} onClick={handleReportServiceTicket}>
              REPORT A SERVICE ISSUE
            </StyledElement>
          </>
        ) : (
          <>
            {[STATUS_ACTIVE, STATUS_OK].includes(status) ? (
              <Typography fontSize={"14px"}>If a network issue continues,</Typography>
            ) : (
              <Typography fontSize={"14px"}>You can</Typography>
            )}
            <ContactSupport text="chat with an agent" />
          </>
        )}
        <Typography fontSize={"14px"}> or call </Typography>
        <StyledA href="tel:1-866-699-4759">1-866-MYZIPLY</StyledA>
        <Typography fontSize={"14px"}> for further research on your issue.</Typography>
      </CenterStack>
    </ColumnCenterStack>
  );

  const connectionSuccessSuppouringText = (
    <ColumnCenterStack gap={0}>
      <CenterStack direction={"row"} flexWrap={"wrap"} gap={1}>
        <Typography fontSize={{ xs: "12px", sm: "13px" }}>You can also</Typography>
        <ContactSupport fontSize={"13px"} text="CHAT" />
        <Typography fontSize={{ xs: "12px", sm: "13px" }}> with an agent or call </Typography>
        <StyledA href="tel:1-866-699-4759">1-866-MYZIPLY</StyledA>
      </CenterStack>
    </ColumnCenterStack>
  );

  const RouterNegitiveSuppouringText = (
    <ColumnCenterStack gap={0}>
      <Typography fontSize={{ xs: "12px", sm: "13px" }}>
        You have some options: You can perform a factory reset on your router.
      </Typography>
      <CenterStack direction={"row"} flexWrap={"wrap"} gap={1}>
        <Typography fontSize={{ xs: "12px", sm: "13px" }}>
          Instructions can be found by clicking
        </Typography>
        <StyledA target="blank" href={ROUTER_CONNECTION_HERE_LINK}>
          HERE
        </StyledA>
        <Typography fontSize={{ xs: "12px", sm: "13px" }}>or, you can</Typography>
        <ContactSupport fontSize={"13px"} text="CHAT WITH AN AGENT" />
        <Typography fontSize={{ xs: "12px", sm: "13px" }}> call </Typography>
        <StyledA href="tel:1-866-699-4759">1-866-MYZIPLY</StyledA>
      </CenterStack>
    </ColumnCenterStack>
  );

  const handleNetworkConnectionStatusMsg = () => {
    const status = networkConnectionDetails?.testStatus?.toLowerCase();
    switch (true) {
      case [STATUS_ACTIVE, STATUS_OK].includes(status):
        setNetworkConnectionStatusMsg(CONNECTION_QUALITY_TEST_MESSAGE);
        setNetworkConnectionStatusSubMsg(CONNECTION_QUALITY_TEST_SUB_MESSAGE);
        setConnectionTestSupportingText(connectionSuccessSuppouringText);
        break;
      case ![STATUS_ACTIVE, STATUS_OK].includes(status):
        setNetworkConnectionStatusMsg(NETWORK_CONNECTION_INACTIVE_TEXT);
        setNetworkConnectionStatusSubMsg(routerConnectionSubText);
        setConnectionTestSupportingText("");
        break;
      default:
        setNetworkConnectionStatusMsg("");
        setNetworkConnectionStatusSubMsg("");
        setConnectionTestSupportingText("");
    }
  };

  const handleRouterConnectionStatus = () => {
    const status = RGStatusDetails?.rgStatus?.toLowerCase();
    switch (true) {
      case [STATUS_ACTIVE, STATUS_OK].includes(status):
        setRouterConnectionStatus(ROUTER_CONNECTION_MESSAGE);
        setRouterTestSupportingText("");
        break;
      case ![STATUS_ACTIVE, STATUS_OK].includes(status):
        setRouterConnectionStatus(ROUTER_CONNECTION_INACTIVE_TEXT);
        setRouterTestSupportingText(RouterNegitiveSuppouringText);
        break;
      default:
        setRouterTestSupportingText("");
        setRouterConnectionStatus("");
    }
  };

  const messagefromRouter = checkRouterTypeMessage && splitMessageBybraces(checkRouterTypeMessage);
  const RGStatusNotNull = RGStatusDetails && Object.keys(RGStatusDetails)?.length > 0;

  useEffect(() => {
    if (networkConnectionDetails) {
      handleNetworkConnectionStatusMsg();
    }
    if (RGStatusDetails) {
      handleRouterConnectionStatus();
    }
  }, [networkConnectionDetails, RGStatusDetails]);

  useEffect(() => {
    setAccountNum(accountNumber);
    setRouterTestSupportingText("");
  }, [accountNumber]);

  return (
    <>
      <CardHeading>Network Status</CardHeading>
      {showOutage && !checkRouterTypeLoading && (
        <OutageBanner showNetworkStatus={showNetworkStatus} showOutage={showOutage} />
      )}

      <StyledCard
        sx={{
          p: "0",
          paddingLeft: "5px",
          paddingTop: "4px",
          mb: messagefromRouter?.length > 0 ? 0 : "",
          top: messagefromRouter?.length > 0 ? 10 : "",
          position: messagefromRouter?.length > 0 ? "relative" : "",
        }}>
        {outageDetailsLoading || checkRouterTypeLoading ? (
          <Box my={5}>
            <Spinner />
          </Box>
        ) : (
          <>
            <Timeline
              sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0,
                },
                padding: "12px",
                paddingBottom: "0px",
              }}
              position="right">
              {supportsSpeedTest === true && (
                <ExpandableSectionHeader
                  icon={speedTestIcon}
                  primaryBoldText={INTERNET_SPEED_TEST}
                  italicText={SPEED_TEST_SUBTEXT}
                />
              )}
              {enableTestYourConnection && (
                <ExpandableSectionHeader
                  icon={networkConnectionIcon}
                  primaryBoldText={CONNECTION_QUALITY_TEST}
                  italicText={CONNECTION_QUALITY_SUBTEXT}
                  primaryBtn={
                    networkConnectionDetails !== null &&
                    networkConnectionStatusMsg === CONNECTION_QUALITY_TEST_MESSAGE
                      ? "TEST AGAIN"
                      : "RUN TEST"
                  }
                  primaryType="payment"
                  data={networkConnectionTestData}
                  error={testConnectionApiError}
                  errorMessage={testConnectionApiErrorMsg}
                  message={networkDetailsNotNull && networkConnectionStatusMsg}
                  submessage={networkDetailsNotNull && networkConnectionStatusSubMsg}
                  accountNum={accountNum}
                  supportingText={ConnectionTestSupportingText}
                />
              )}

              {enableRgStatus && (
                <ExpandableSectionHeader
                  icon={routerIcon}
                  primaryBoldText={ROUTER_CONNECTION_TEST}
                  italicText={ROUTER_CONNECTION_SUBTEXT}
                  primaryBtn={"Run test"}
                  onClickSecondary={handleRebootRG}
                  primaryType="payment"
                  secondaryType="pay"
                  secondaryBtn={RGStatusNotNull && REBOOT_ROUTER}
                  data={homeRouterData}
                  rebootLoading={rebootLoading}
                  error={RGStatusApiError}
                  errorMessage={RGStatusApiErrorMsg}
                  message={RGStatusNotNull && routerConnectionStatus}
                  submessage={RGStatusNotNull && routerConnectionSubText}
                  accountNum={accountNum}
                  supportingText={routerTestSupportingText}
                  setRouterTestSupportingText={setRouterTestSupportingText}
                />
              )}
            </Timeline>

            {enableBreakLease && (
              <>
                {(supportsSpeedTest || enableTestYourConnection || enableRgStatus) && (
                  <AccountDivider />
                )}
                <StyleAccordian
                  disableGutters
                  elevation={0}
                  expanded={expanded === PANEL_1}
                  onChange={handleChange(PANEL_1)}>
                  <StyleAccordionSummary
                    expandIcon={
                      <StyledElement>
                        <ExpandMoreIcon />
                      </StyledElement>
                    }>
                    <StyledElement fontSize={{ xs: "0.8rem", sm: "1rem" }}>
                      QUICK FIX TOOLKIT
                    </StyledElement>
                  </StyleAccordionSummary>
                  <Box
                    pt={1}
                    px={2.5}
                    sx={{ backgroundColor: Theme.palette.customcolor.accordionbackground }}>
                    {!breakLeaseLoading && (
                      <>
                        <Typography fontSize={{ xs: "10px", md: "14px" }}>
                          Release my IP Address
                        </Typography>
                        <Typography
                          fontSize={{ xs: "10px", md: "14px" }}
                          color={Theme.palette.customcolor.customText}>
                          This will release your Public IP Address and assign you a new IP address
                        </Typography>
                      </>
                    )}
                    {breakLeaseMessage && !breakLeaseLoading && (
                      <Typography
                        fontSize={{ xs: "13px", md: "16px" }}
                        textAlign={"center"}
                        mt={2}
                        color={
                          breakLeaseMessage?.toLowerCase()?.includes("completed")
                            ? Theme.palette.primary.main
                            : Theme.palette.error.main
                        }>
                        {breakLeaseMessage}
                      </Typography>
                    )}
                    {breakLeaseLoading && (
                      <CenterStack py={5}>
                        <Typography
                          my={1}
                          fontSize={{ xs: "10px", md: "14px" }}
                          color={Theme.palette.customcolor.customText}>
                          Releasing IP Address
                        </Typography>
                        <LinearDeterminateProgress />
                      </CenterStack>
                    )}
                    {!breakLeaseLoading && (
                      <CenterStack py={2}>
                        <Button title="Release IP Address" type="root" onClick={handleBreakLease} />
                      </CenterStack>
                    )}
                  </Box>
                </StyleAccordian>
              </>
            )}
          </>
        )}
      </StyledCard>
      {messagefromRouter?.length > 0 && !(outageDetailsLoading || checkRouterTypeLoading) && (
        <SpeedTestMessageCard>
          <Stack spacing={1} mt={1}>
            <Typography fontWeight={600}>{messagefromRouter[0]}</Typography>
            <Typography fontSize={{ xs: "14px", md: "16px" }}>{messagefromRouter[1]}</Typography>
          </Stack>
          <Stack direction={"row"} spacing={0.5} my={1} alignItems={"center"}>
            <Typography fontSize={{ xs: "14px", md: "14px" }}>Please contact </Typography>
            <ContactSupport text={"our team"} />
            <Typography fontSize={{ xs: "14px", md: "14px" }}>
              to upgrade to a Ziply Fiber WiFi router.
            </Typography>
          </Stack>
        </SpeedTestMessageCard>
      )}

      <SuccessDialogBox
        open={breakLeaseSuccess}
        onClose={onCloseSuccessDialog}
        heading={BREAK_LEASE_SUCCESSFUL}
        message={
          <ColumnCenterStack gap={1} my={1}>
            <Typography>Your device has been assigned a new IP address</Typography>
          </ColumnCenterStack>
        }
      />

      <SuccessFailureDialog
        open={breakLeaseApiError}
        onClose={onCloseFailureDialog}
        heading={BREAK_LEASE_FAILED}
        message={
          <ColumnCenterStack gap={1} my={1}>
            <Typography>We couldn't refresh your network connection at the moment.</Typography>
            <Typography>Please try again later.</Typography>
          </ColumnCenterStack>
        }
      />

      <SuccessFailureDialog
        open={rebootRGApiError}
        onClose={onCloseRebootFailure}
        heading={REBOOT_ERROR}
        message={
          <ColumnCenterStack gap={1} my={1}>
            <Typography>Could not reboot your router currently. Please try again.</Typography>
            <Stack direction={"row"} justifyContent={"center"} gap={1}>
              <Typography fontSize={{ xs: "14px", md: "16px" }}>Please </Typography>
              <ContactSupport />
              <Typography> for further help.</Typography>
            </Stack>
          </ColumnCenterStack>
        }
      />
    </>
  );
};

const SpeedTestMessageCard = styled(Card)(({ theme }) => ({
  background: Theme.palette.customcolor.skyBlue,
  boxShadow: "0px 0px 4px 0px rgb(193, 193, 193)",
  padding: "14px",
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  elevation: 0,
}));

export default NetworkStatus;

import PortInfo from "./PortInfo";
import Radio from "@mui/material/Radio";
import { useEffect, useState } from "react";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import { Theme } from "../../../../Styles/GlobalTheme";
import Button from "../../../../components/Button/Button";
import DirectoryListingInfo from "./DirectoryListingInfo";
import { getDollarSign } from "../../../../Services/helper";
import { Box, Grid, Stack, Typography } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import { getEquipmentName, isMgRouter, renderRouterType, routerCheck } from "./OrderHelper";
import { AddOnsCard, CenterStack } from "../../../../Styles/GlobalStyles";
import { Spinner } from "../../../../components/shared/LoadingSpinner/Spinner";
import { ErrorComponent } from "../../../../components/ErrorComponent/ErrorComponent";
import SuccessFailureDialog from "../../../Home/Components/NetworkStatus/SuccessFailureDialog";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { TEN_GIG_INSTALLATION } from "../../../../Constants/constants";

const SelectEquipment = ({
  addOnsLoading,
  existingPlanDetails,
  futureAddOnPlanErr,
  handleGetFutureAddOns,
  setSelectedPhoneItem,
  selectedPhoneItem,
  showRouterType,
  isAlreadyPhoneUnlimited,
  futureAddOnPlanData,
  addonContent,
  addOnContentErr,
  addOnContentLoading,
  handleContentBySkus,
  setPortInfo,
  portInfo,
  setListingInfo,
  listingInfo,
  setFutureRouterArray,
  futureRouterArray,
  selectPhoneVal,
  setSelectPhoneVal,
  homePhoneVal,
  setHomePhoneVal,
  NewPhoneErrors,
  portErros,
  setNewPhoneErrors,
  setPortErrors,
  addPhone,
  setAddPhone,
  submitOrderArr,
  setSubmitOrderArr,
  setInstallation,
  isNDS,
  copperToFiber,
  setUpdateRouterType,
  isByoRouter,
  setShowRouterType,
  setIsAlreadyPhoneUnlimited,
  isHomeInExistingAddon,
  isHavingMatchingRouter,
  setSelectedFiber,
  selectedFiber,
  updateRouterType,
  selectedPlanDetails,
}: any) => {
  const [openDialog, setOpenDialog] = useState(false);

  const groupedAddons =
    futureAddOnPlanData?.newAddons &&
    futureAddOnPlanData?.newAddons?.reduce((acc: any, addon: any) => {
      const existingGroup = acc?.find((group: any) => group?.groupType === addon?.groupType);

      if (existingGroup) {
        existingGroup?.items?.push(addon);
      } else {
        acc?.push({
          groupType: addon?.groupType,
          items: [addon],
        });
      }

      return acc;
    }, []);

  const isEmptyObject = (obj: any) => {
    return Object.keys(obj).length === 0;
  };

  const handleSelectPhone = (group: any) => {
    let installationObj =
      futureAddOnPlanData?.oneTimeFee &&
      futureAddOnPlanData?.oneTimeFee?.length > 0 &&
      futureAddOnPlanData?.oneTimeFee[0];
    const phoneItem = group?.items?.[0];
    if (!isMgRouter(existingPlanDetails)) {
      if (showRouterType !== "allRouter") {
        if (showRouterType === "updateToWifi6") {
          setUpdateRouterType(false);
          setShowRouterType("allRouter");
        } else if (isHavingMatchingRouter && selectedFiber?.productId !== "WIFI7_RTR") {
          setUpdateRouterType(false);
        } else {
          setShowRouterType("allRouter");
          setUpdateRouterType(true);
        }
      } else if (isByoRouter) {
        setUpdateRouterType(true);
        setShowRouterType("updateToWifi6");
      } else {
        if (!isByoRouter && !isHavingMatchingRouter) {
          setUpdateRouterType(true);
          setShowRouterType("updateToWifi6");
        } else {
          setSubmitOrderArr((prev: any) => prev.slice(0, 1));
          setFutureRouterArray((prev: any) => prev.slice(0, 1));
          setShowRouterType("updateHomePhone");
          setUpdateRouterType(false);
        }
      }
    }
    if (isEmptyObject(selectedPhoneItem)) {
      setFutureRouterArray([...futureRouterArray, phoneItem]);
      setSubmitOrderArr([...submitOrderArr, phoneItem]);
      setSelectedPhoneItem(phoneItem);
      setAddPhone(true);
      setInstallation(installationObj);
    } else {
      const removeAddphone = submitOrderArr?.filter(
        (item: any) => item?.productId !== phoneItem?.productId
      );
      const removeFutureAddphone = futureRouterArray?.filter(
        (item: any) => item?.productId !== phoneItem?.productId
      );
      setAddPhone(false);
      setSelectedPhoneItem({});
      setFutureRouterArray(removeFutureAddphone);
      setSubmitOrderArr(removeAddphone);
      setInstallation({});
    }
  };

  const handleChange = (e: any) => {
    setSelectPhoneVal(e.target.value);
  };

  const handleChangeHomePhone = (e: any) => {
    if (e.target.value === "subscribed") {
      setFutureRouterArray([...futureRouterArray, selectedPhoneItem]);
      setSubmitOrderArr([...submitOrderArr, selectedPhoneItem]);
    } else if (e.target.value === "unSubscribed") {
      const removeAddphone = submitOrderArr?.filter(
        (item: any) => item?.productId !== selectedPhoneItem?.productId
      );
      const removeFutureAddphone = futureRouterArray.filter(
        (item: any) => item?.productId !== selectedPhoneItem?.productId
      );
      setFutureRouterArray(removeFutureAddphone);
      setSubmitOrderArr(removeAddphone);
    }
    setHomePhoneVal(e.target.value);
  };

  const handleChangeRadio = (e: any) => {
    setAddPhone(false);
    const filterData = groupedAddons
      ?.filter(
        (group: any) =>
          (group?.groupType === "Router" || group?.groupType === "Equipment") &&
          group?.items.some((item: any) => item?.productId === e.target.value)
      )
      ?.map((group: any) => ({
        ...group,
        item: group.items.find((item: any) => item?.productId === e.target.value),
      }));
    let removeArr = filterData[0]?.item;
    let filterRouter = { ...removeArr, isRouter: true };
    const filterSubmitOrder = submitOrderArr?.filter((item: any) => !item?.isRouter);
    const updatedFutureSubmit = [...filterSubmitOrder, filterRouter];
    const filterFutureRouterArray = futureRouterArray?.filter((item: any) => !item?.isRouter);
    const updatedFutureSubmitedArray = [...filterFutureRouterArray, filterRouter];
    const homePhone = groupedAddons.find((group: any) => group.groupType === "Voice Addon");
    let isVoiceInExisting = isNDS
      ? existingPlanDetails?.addons?.find((item: any) =>
          item?.addonItemCode === "VOIP_ADD_HDR" ? true : false
        )
      : existingPlanDetails?.planDescription?.includes("Home Phone Unlimited");
    if (e.target.value === "BYO_ROUTER") {
      if (isHomeInExistingAddon || (!isHomeInExistingAddon && homePhone?.items?.length > 0)) {
        setShowRouterType("allRouter");
        setIsAlreadyPhoneUnlimited("disableHomePhone");
      } else {
        setIsAlreadyPhoneUnlimited("");
      }

      let newUpdated = updatedFutureSubmit.filter((item: any) => item?.groupType !== "Voice Addon");
      let newUpdatedFut = updatedFutureSubmitedArray.filter(
        (item) => item?.groupType !== "Voice Addon"
      );

      setSubmitOrderArr(newUpdated);
      setFutureRouterArray(newUpdatedFut);
      setSelectedFiber(removeArr);
    } else {
      if (homePhone?.items?.length > 0) {
        let newRouter = { ...removeArr };
        setSelectedPhoneItem({});
        newRouter.updateToWifi6 = false;
        if ((isByoRouter || (!isByoRouter && !isHavingMatchingRouter)) && updateRouterType) {
          setUpdateRouterType(null);
        }
        setSelectedFiber(newRouter);

        setIsAlreadyPhoneUnlimited("");
      } else {
        if (!isVoiceInExisting) {
          setIsAlreadyPhoneUnlimited("");
        } else {
          setIsAlreadyPhoneUnlimited("notSupported");
        }
        setSelectedFiber(removeArr);
      }
      setFutureRouterArray(updatedFutureSubmitedArray);
      setSubmitOrderArr(updatedFutureSubmit);
    }
  };
  const homePhoneSku = groupedAddons
    ?.filter((group: any) => group?.groupType === "Voice Addon")
    .map((group: any) => group?.items[0]?.sku)[0];
  const homePhoneContent = addonContent
    .map((addon: any) => {
      if (addon?.sku === homePhoneSku) {
        return addon?.description;
      }
      return null;
    })
    .filter((description: any) => description !== null);

  const dialogMsg = (
    <CenterStack spacing={2} mt={4}>
      <Typography component={"p"} textAlign={"center"} fontSize={{ xs: "13px", md: "16px" }}>
        In order to port your number we need two things, your existing phone number and the account
        number with your current provider.
      </Typography>
      <Typography component={"p"} textAlign={"center"} fontSize={{ xs: "13px", md: "16px" }}>
        If you would like to transfer your existing phone number later, you can complete your order
        and contact customer care at you convenience.
      </Typography>
    </CenterStack>
  );

  useEffect(() => {
    addPhone && selectPhoneVal === "existingPhone" && setOpenDialog(true);
  }, [addPhone, selectPhoneVal]);

  return (
    <>
      {addOnsLoading || addOnContentLoading ? (
        <Box my={10}>
          <Spinner />
        </Box>
      ) : futureAddOnPlanErr && !addOnsLoading ? (
        <Box my={10}>
          <ErrorComponent onRefresh={handleGetFutureAddOns} />
        </Box>
      ) : (
        <Box>
          <Typography
            py={1}
            fontSize={{ xs: "16px", md: "18px" }}
            fontWeight={600}
            color={Theme.palette.primary.main}>
            {existingPlanDetails &&
              renderRouterType(existingPlanDetails, isNDS || copperToFiber)?.heading}
          </Typography>
          {existingPlanDetails &&
            renderRouterType(existingPlanDetails, isNDS || copperToFiber)?.description &&
            showRouterType !== "useMyOwnRouter" && (
              <Typography>
                {existingPlanDetails &&
                  renderRouterType(existingPlanDetails, isNDS || copperToFiber)?.description}
              </Typography>
            )}
          {selectedPlanDetails?.productId === "FIBER_10G" && (
            <Stack pb={2} direction="row" alignItems="center" spacing={1}>
              <InfoOutlinedIcon sx={{ color: Theme.palette.customcolor.profileTypoGraphyColor }} />
              <Typography
                fontSize={{ xs: "14px", md: "16px" }}
                sx={{ color: Theme.palette.customcolor.profileTypoGraphyColor }}>
                {TEN_GIG_INSTALLATION}
              </Typography>
            </Stack>
          )}
          {showRouterType === "useMyOwnRouter" && (
            <AddOnsCard alreadyAdded={false} sx={{ width: "100%", padding: "1.5rem", my: 2 }}>
              <Typography fontSize={{ xs: "14px", md: "16px" }} color={Theme.palette.primary.main}>
                Select WiFi Equipment
              </Typography>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value="Use my existing equipment"
                  name="radio-buttons-group">
                  <FormControlLabel
                    value="Use my existing equipment"
                    control={<Radio />}
                    label={<Typography fontWeight={600}>Use my existing equipment</Typography>}
                  />
                  <Typography ml={4} fontSize={{ xs: "14px", md: "16px" }}>
                    Your current router is the best option for your new speed. No changes are
                    necessary on your end.
                  </Typography>
                </RadioGroup>
              </FormControl>
            </AddOnsCard>
          )}
          {(showRouterType === "allRouter" || showRouterType === "updateToWifi6") && (
            <AddOnsCard alreadyAdded={false} sx={{ width: "100%", padding: "1.5rem" }}>
              <Typography fontSize={{ xs: "14px", md: "16px" }} color={Theme.palette.primary.main}>
                Select WiFi Equipment
              </Typography>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={selectedFiber?.productId}
                  onChange={handleChangeRadio}
                  name="radio-buttons-group">
                  {groupedAddons?.map((group: any, index: number) => (
                    <Stack key={index}>
                      {(group?.groupType === "Router" || group?.groupType === "Equipment") &&
                        routerCheck(group.items).map((item: any) => (
                          <>
                            <FormControlLabel
                              value={item.productId}
                              control={<Radio />}
                              label={
                                <Typography fontWeight={600}>{`${
                                  item?.productId === selectedFiber?.productId &&
                                  selectedFiber.updateToWifi6 === true
                                    ? getEquipmentName(
                                        futureAddOnPlanData?.fiberRouterSKU,
                                        addonContent
                                      ) ?? "Whole Home Wifi "
                                    : getEquipmentName(item?.sku, addonContent) ?? item.name
                                } - ${getDollarSign(item?.price)}/mo`}</Typography>
                              }
                            />
                            {!addOnContentLoading &&
                              addonContent?.map((addOn: any) => {
                                return (
                                  <>
                                    {addOn?.sku ===
                                      (item?.updateToWifi6
                                        ? futureAddOnPlanData?.fiberRouterSKU
                                        : item.sku) && (
                                      <>
                                        <Typography
                                          px={{ xs: 4, md: 6 }}
                                          dangerouslySetInnerHTML={{ __html: addOn?.description }}
                                        />
                                      </>
                                    )}
                                  </>
                                );
                              })}
                          </>
                        ))}
                    </Stack>
                  ))}
                </RadioGroup>
              </FormControl>
            </AddOnsCard>
          )}
          {isAlreadyPhoneUnlimited !== "disableHomePhone" &&
            groupedAddons?.map(
              (group: any, index: number) =>
                group?.groupType === "Voice Addon" && (
                  <Box key={index}>
                    {isAlreadyPhoneUnlimited === "alreadyAdded" ? (
                      <>
                        {selectedFiber?.name?.toLowerCase() !== "no router" && (
                          <AddOnsCard
                            alreadyAdded={false}
                            sx={{ width: "100%", padding: "1.5rem" }}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6}>
                                <Stack spacing={2}>
                                  <Typography fontWeight={600}>Home Phone Unlimited</Typography>
                                  <Typography>
                                    You are already subscribed to this service.
                                  </Typography>
                                  <Typography>
                                    Do you want to continue with this service?(We will remove the
                                    charge from your monthly bill?)
                                  </Typography>
                                </Stack>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <Stack spacing={2}>
                                  <Typography fontWeight={600}>$20/mo</Typography>

                                  <FormControl>
                                    <RadioGroup
                                      aria-labelledby="demo-controlled-radio-buttons-group"
                                      name="controlled-radio-buttons-group"
                                      value={homePhoneVal}
                                      onChange={handleChangeHomePhone}>
                                      <FormControlLabel
                                        value="subscribed"
                                        control={<Radio />}
                                        label="Keep me subscribed to Home Phone"
                                      />
                                      <FormControlLabel
                                        value="unSubscribed"
                                        control={<Radio />}
                                        label="Unsubscribe me from Home Phone"
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </Stack>
                              </Grid>
                            </Grid>
                          </AddOnsCard>
                        )}
                      </>
                    ) : (
                      <>
                        {selectedFiber?.name?.toLowerCase() !== "no router" && (
                          <AddOnsCard alreadyAdded={true} sx={{ width: "100%", paddingX: "2rem" }}>
                            <Grid container>
                              <Grid item xs={12} sm={6}>
                                <Stack>
                                  <Typography fontWeight={600}>Add Home Phone Unlimited</Typography>
                                  <Typography
                                    component="div"
                                    dangerouslySetInnerHTML={{
                                      __html: homePhoneContent[0] ?? "",
                                    }}
                                    style={{ paddingLeft: "20px", margin: "15px" }}
                                  />
                                </Stack>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <Stack>
                                  <Typography
                                    fontWeight={600}
                                    fontSize={{ xs: "18px", md: "20px" }}>
                                    $20/mo
                                  </Typography>
                                  <Box my={1}>
                                    <Button
                                      type={addPhone ? "selected" : "select"}
                                      title={addPhone ? "ADDED PHONE" : "ADD PHONE"}
                                      onClick={() => handleSelectPhone(group)}
                                    />
                                  </Box>
                                  {addPhone && (
                                    <FormControl>
                                      <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={selectPhoneVal}
                                        onChange={handleChange}>
                                        <FormControlLabel
                                          value="newPhone"
                                          control={<Radio />}
                                          label="I want a new phone number."
                                        />
                                        <FormControlLabel
                                          value="existingPhone"
                                          control={<Radio />}
                                          label="I want to keep my existing number."
                                        />
                                      </RadioGroup>
                                    </FormControl>
                                  )}
                                </Stack>
                              </Grid>
                            </Grid>
                          </AddOnsCard>
                        )}
                        {addPhone && selectPhoneVal === "newPhone" && (
                          <Box my={4}>
                            <DirectoryListingInfo
                              listingInfo={listingInfo}
                              setListingInfo={setListingInfo}
                              errors={NewPhoneErrors}
                              setErrors={setNewPhoneErrors}
                            />
                          </Box>
                        )}
                        {addPhone && selectPhoneVal === "existingPhone" && (
                          <>
                            <Box my={4}>
                              <DirectoryListingInfo
                                listingInfo={listingInfo}
                                setListingInfo={setListingInfo}
                                errors={NewPhoneErrors}
                                setErrors={setNewPhoneErrors}
                              />
                            </Box>
                            {(copperToFiber || isNDS) && (
                              <Box my={3}>
                                <PortInfo
                                  setPortInfo={setPortInfo}
                                  portInfo={portInfo}
                                  errors={portErros}
                                  setErrors={setPortErrors}
                                />
                              </Box>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Box>
                )
            )}
          {(isAlreadyPhoneUnlimited === "notSupported" ||
            isAlreadyPhoneUnlimited === "disableHomePhone") && (
            <>
              <AddOnsCard alreadyAdded={false} sx={{ width: "100%", border: "1px solid black" }}>
                <Stack spacing={1.5}>
                  <Typography fontWeight={600}>Home Phone not supported</Typography>
                  <Typography>
                    Unfortunately, we cannot provide phone service via the equipment used for this
                    internet speed.
                  </Typography>
                  {isAlreadyPhoneUnlimited === "notSupported" && (
                    <Typography>We will remove the charge from your monthly bill.</Typography>
                  )}
                </Stack>
              </AddOnsCard>
            </>
          )}
          {selectPhoneVal === "existingPhone" && (
            <>
              <SuccessFailureDialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                message={dialogMsg}
                heading={"We'll give you a call"}
              />
            </>
          )}
        </Box>
      )}
    </>
  );
};

export default SelectEquipment;

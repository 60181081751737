import { Box, Grid, Stack } from "@mui/material";
import { formatPhoneNumber } from "../../../../Services/helper";
import { ErrorMessage, ProfileInput, ProfileTypography } from "../../../../Styles/GlobalStyles";

export const ContactInfoVerification = ({
  formData,
  setFormData,
  formErrors,
  setFormErrors,
}: any) => {
  const handleChangePhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, phoneNumber: formatPhoneNumber(e.target.value) });
    setFormErrors({ phoneNumber: "", secondaryPhone: "", customerNotes: "" });
  };

  const handleChangeSecondaryPhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, secondaryPhone: formatPhoneNumber(e.target.value) });
    setFormErrors({ phoneNumber: "", secondaryPhone: "", customerNotes: "" });
  };

  const handleChangeCustomerNotes = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, customerNotes: e.target.value });
    setFormErrors({ phoneNumber: "", secondaryPhone: "", customerNotes: "" });
  };

  return (
    <>
      <Box m={2}>
        <Grid
          container
          direction={"row"}
          my={1}
          gap={1}
          justifyContent={"space-around"}
          width={"100%"}>
          <Grid item xs={12} md={5.5} gap={4}>
            <ProfileTypography>Mobile Number</ProfileTypography>
            <ProfileInput
              required
              error={formErrors.phoneNumber ? true : false}
              name="phoneNumber"
              type="text"
              fullWidth
              placeholder="(###)-###-####"
              value={formData.phoneNumber}
              onChange={handleChangePhoneNumber}
            />
            {formErrors.phoneNumber && (
              <ErrorMessage error={formErrors?.phoneNumber ? true : false}>
                {formErrors?.phoneNumber}
              </ErrorMessage>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <ProfileTypography>Secondary Mobile Number</ProfileTypography>
            <ProfileInput
              name="secondaryPhone"
              type="text"
              fullWidth
              placeholder="(###)-###-####"
              value={formData.secondaryPhone}
              onChange={handleChangeSecondaryPhone}
            />
          </Grid>
        </Grid>
        <Stack my={2} spacing={1}>
          <ProfileTypography>
            Please enter any additional information about your specific issues
          </ProfileTypography>
          <ProfileInput
            name="note"
            placeholder="Add Note"
            fullWidth
            type="text"
            value={formData.customerNotes}
            onChange={handleChangeCustomerNotes}
            multiline
            rows={3}
          />
        </Stack>

        <Stack my={2} spacing={1}>
          <ProfileTypography>Note : </ProfileTypography>
          <ProfileTypography>
            There is a potential for additional charges is we send a repair technician to a
            residence and the trouble is found in the customer'sinside wiring or in third-party
            equipment. Explicit permissionfrom the account owner will be obtained before any work is
            initiated.
          </ProfileTypography>
        </Stack>
      </Box>
    </>
  );
};

import { ErrorPopup } from "./ErrorPopup";
import { VerifyInfo } from "./VerifyInfo";
import SlotSelection from "./SlotSelection";
import { useEffect, useState } from "react";
import SelectNewSpeed from "./SelectNewSpeed";
import SelectEquipment from "./SelectEquipment";
import MonthlyBillCart from "./MonthlyBillCart";
import BroadbandFactsCard from "./BroadbandFactsCard";
import { useDispatch, useSelector } from "react-redux";
import StepperWithoutLabels from "./StepperWithoutLabels";
import { useLazyQuery, useMutation } from "@apollo/client";
import Button from "../../../../components/Button/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Grid, Stack, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import NutritionLabel from "../NutritionLabel/NutritionLabel";
import AutoPayAndPaperlessError from "./AutoPayAndPaperlessError";
import greenTickIcon from "../../../../assets/icons/Group 1024.png";
import { Spinner } from "../../../../components/shared/LoadingSpinner/Spinner";
import { AUTOPAY_PAPERLESS_DETAILS, SET_PAPERLESS } from "../../../Billing/query";
import { paperLessTurnedOn } from "../../../../Redux/Slices/AutoPayPaperlessSlice";
import { AccountCard } from "../../../../components/shared/AccountCard/AccountCard";
import { ErrorComponent } from "../../../../components/ErrorComponent/ErrorComponent";
import { NeedHelpCard } from "../../../../components/shared/NeedHelpCard/NeedHelpCard";
import { LoadingSpinner } from "../../../../components/shared/LoadingSpinner/LoadingSpinner";
import { usePhoneNumberValidation } from "../../../../hooks/Validation/usePhoneNumberValidation";
import { SuccessMessageModel } from "../../../../components/shared/SuccessMessageModel/SuccessMessageModel";
import {
  AUTOPAY_AND_PAPERLESS_UPGRADE_ORDER_ERROR,
  AUTOPAY_UPGRADE_ORDER_ERROR,
  PAPERLESS_UPGRADE_ORDER_ERROR,
  TURNON_PAPERLESS_SUCCESS_MESSAGE,
} from "../../../../Constants/constants";
import {
  setAutopaySuccess,
  setAvailablePlansError,
  setCustomerPlanDetailsError,
  setIsSelected,
  setNutritionLabelbyPlanError,
  setShowNextinAutoPay,
  setSlotSelected,
  setZoomOutFlag,
} from "../../../../Redux/Slices/OrderUpgradeSlice";
import {
  addSpaceAfterComma,
  extractPhoneNumber,
  formatPhoneNumber,
  parseAddress,
  validInputSubmit,
} from "../../../../Services/helper";
import {
  CardHeading,
  PageGrid,
  StickyBox,
  StyledCard,
  StyledLink,
} from "../../../../Styles/GlobalStyles";
import {
  checkAddonItemCode,
  findMatchingAddon,
  findMatchingItem,
  findMatchingItemOrderSubmit,
  findRemovedAddons,
  removeMatchedAddons,
  transformAddons,
  transformAddonsLegacy,
  transformRemovedAddons,
  transformRemovedAddonsLegacy,
} from "./OrderHelper";
import {
  COMMIT_ORDER,
  FALLOUT_ORDER,
  GET_ADDONS_FOR_FUTURE_PLAN,
  GET_ADDON_DETAILS_BY_SKU,
  GET_AVAILABLE_PLANS_FOR_UPGRADE,
  GET_CUSTOMER_PLAN_DETAILS,
  GET_PRIMARY_EMAIL,
  NUTRITION_LABEL_BY_PLAN_DETAILS,
  SUBMIT_ORDER_LEGACY,
  SUBMIT_ORDER_UPGRADE,
} from "./query";

export interface planProps {
  name: string;
  price: string;
  contentSku?: string;
  discounts?: any;
  priceAfterDiscount?: any;
  productId: any;
}

export interface CustomerAddress {
  addressLine: string;
  city: string;
  state: string;
  zip: string;
}
interface customerPlanDetailsProps {
  addressId: string;
  planSpeed: string;
  itemCode: string;
  subsidyName: string;
  uuid: string;
  usi: string;
  sessionId: string;
}
const OrderUpgrades = ({ getorderDetails, getPlansServices, data }: any) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { fromBanner = false } = location.state || {};
  const {
    accountNumber,
    accountUuid,
    serviceAddress,
    usi,
    billingAddress,
    btn,
    CTFOfferId,
    copperToFiber,
    CTFOfficeCode,
    env,
    samControlNumber,
    timeZone,
    rateCenter,
  } = data;
  const { scrollPosition } = useSelector((state: any) => state.pageInfo);
  const { givenNameFamilyName, linkedAccountNumbers, emailContact, telephoneContact, ndsObj, pin } =
    useSelector((state: any) => state.customerInfoDetails);
  const { paperLessData, autoPayData, uuidErrorFlag, defaultPrice } = useSelector(
    (state: any) => state?.autopayPaperlessDetails
  );
  const { autopayAmount, paperlessAmount, paymentsMigrationToNDS } = useSelector(
    (state: any) => state.customerPlanDetails
  );
  const addressFromBillingDetails = useSelector(
    (state: any) => state.customerPlanDetails.billingAddress
  );

  const {
    customerPlandetailsError,
    selectedPlanDetails,
    nutritionLabelbyPlanError,
    isSelected,
    ZoomOutFlag,
    showNextinAutoPay,
    autopaySuccess,
    autopayFailure,
    selectedSlot,
  } = useSelector((state: any) => state.OrderUpgradeDetails);
  const [activeStep, setActiveStep] = useState<number>(1);
  const [highestPlan, setHighestPlan] = useState("");
  const [showRouterType, setShowRouterType] = useState("");
  const [dependencyPrerequisite, setDependencyPrerequisite] = useState<any>([]);
  const [stepperHeading, setStepperHeading] = useState<string>("Select New Speed");
  const [selectPlan, setSelectPlan] = useState<number | null>(null);
  const [showBroadbandFacts, setShowBroadbandFacts] = useState<boolean>(false);
  const [showMore, setShowMore] = useState<boolean>(false);
  const email = emailContact?.emailAddress;
  const phone = telephoneContact?.telephoneNumber;
  const [phoneNumber, setPhoneNumber] = useState<string>(phone);
  const [seId, setSeId] = useState<string>("");
  const [futureAddOnPlanData, setFutureAddOnPlanData] = useState<any>([]);
  const [futureAddOnPlanErr, setFutureAddOnPlanErr] = useState(false);
  const [selectedPhoneItem, setSelectedPhoneItem] = useState<any>({});
  const [isAlreadyPhoneUnlimited, setIsAlreadyPhoneUnlimited] = useState("");
  const [addonContent, setAddonContent] = useState([]);
  const [checkPaperless, setCheckPaperless] = useState(false);
  const [checkAutopay, setCheckAutopay] = useState(false);
  const [selectedFiber, setSelectedFiber] = useState<any>({});
  const [enableAutopayPaperless, setEnableAutopayPaperless] = useState(false);
  const [verifyInfoErrors, setVerifyInfoErrors] = useState<any>({});
  const [paperlessError, setPaperlessError] = useState(false);
  const [openAutopay, setOpenAutopay] = useState(false);
  const [selectPhoneVal, setSelectPhoneVal] = useState("newPhone");
  const [homePhoneVal, setHomePhoneVal] = useState("subscribed");
  const [submitOrderPayload, setSubmitOrderPayload] = useState<any>({});
  const [submitOrderResponse, setSubmitOrderResponse] = useState<any>({});
  const [futureRouterArray, setFutureRouterArray] = useState<any>([]);
  const [submitOrderArr, setSubmitOrderArr] = useState<any>([]);
  const [addPhone, setAddPhone] = useState(false);
  const [successScreen, setSuccessScreen] = useState(false);
  const [selectedSlotObj, setSelectedSlotObj] = useState<any>({});
  const [falloutErr, setFalloutErr] = useState(false);
  const [submitOrderErr, setSubmitOrderErr] = useState<any>({});
  const [submitOrderFail, setSubmitOrderFail] = useState(false);
  const [submitOrderLegacyErr, setSubmitOrderLegacyErr] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState<any>({});
  const [installation, setInstallation] = useState<any>(null);
  const [availablePlansData, setAvailablePlansData] = useState<any>([]);
  const [promotion, setPromotion] = useState<any>();
  const [showErrorScreen, setShowErrorScreen] = useState<boolean>(false);
  const [install, setInstall] = useState<boolean | null>(null);
  const [AddonsForFuturePlanError, setAddonsForFuturePlanError] = useState(false);
  const [successMessage, setSuccessMessage] = useState<boolean>(false);
  const [userStatus, setUserStatus] = useState<string>("");
  const [orderNumber, setOrderNumber] = useState("");
  const [updateRouterType, setUpdateRouterType] = useState<boolean | null>(false);
  const [isByoRouter, setIsByoRouter] = useState({});
  const [isHomeInExistingAddon, setIsHomeInExistingAddon] = useState(false);
  const [isHavingMatchingRouter, setIsHavingMatchingRouter] = useState(false);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [orderTotalPrice, setOrderTotalPrice] = useState<any>({
    totalPrice: 0,
    estimatedTax: 0,
  });
  const [listingInfo, setListingInfo] = useState<any>({
    firstName: "",
    lastName: "",
    address: "",
    voicemail: "",
    city: "",
    state: "",
    zipcode: "",
  });
  const [NewPhoneErrors, setNewPhoneErrors] = useState({
    firstName: "",
    lastName: "",
    address: "",
    voicemail: "",
    city: "",
    state: "",
    zipcode: "",
  });
  const [portInfo, setPortInfo] = useState<any>({
    portInNumber: "",
    accountNumber: "",
    existingPin: "",
    existingTelecomAddress: "",
  });
  const [portErros, setPortErrors] = useState<any>({
    portInNumber: "",
    accountNumber: "",
    existingPin: "",
    existingTelecomAddress: "",
  });

  const isNDS = ndsObj?.isNDS;

  const handleValidation = (name: any) => {
    const errors = validInputSubmit({ phoneNumber });
    if (Object.keys(errors).length > 0) {
      setVerifyInfoErrors({ ...verifyInfoErrors, [name]: errors[name] });
    }
  };

  const [getPrimaryEmail, { loading: primaryEmailLoading, data: primaryEmailData }] = useLazyQuery(
    GET_PRIMARY_EMAIL,
    {
      variables: {
        accountUuid: accountUuid,
        email: email,
        accountNumber: accountNumber,
      },
      fetchPolicy: "network-only",
    }
  );

  const getCustomerName = () => {
    if (data?.firstName && data?.lastName) {
      setFirstName(data?.firstName ?? "");
      setLastName(data?.lastName ?? "");
    } else {
      setFirstName(givenNameFamilyName?.givenName ?? "");
      setLastName(givenNameFamilyName?.familyName ?? "");
    }
  };

  const primaryEmail = primaryEmailData?.getPrimaryEmailByAccountUuid?.primaryEmail;

  const [getCustomerPlanDetails, { loading: planDetailsLoading, data: customerPlanDetails }] =
    useLazyQuery(GET_CUSTOMER_PLAN_DETAILS, {
      onCompleted: (data) => {
        let addressDetails = data?.getCustomerPlanDetails;
        handleAvailablePlans(data?.getCustomerPlanDetails);
        const [addressLine, cityName, stateOrProvince, postalCode] = billingAddress?.split(",");
        const [serViceAddressLine, serviceCityName, serviceStateOrProvince, servicePostalCode] =
          serviceAddress?.split(",");

        const updatedAddressInfoCTF = {
          SAMControlNo: samControlNumber,
          environment: env,

          AddressId: addressDetails?.addressId,
          officeCode: CTFOfficeCode,
          timeZone: timeZone,

          billingAddress: {
            addressLine: addressLine,
            cityName: cityName,
            stateOrProvince: stateOrProvince,
            postalCode: postalCode,
            country: "",
          },
          serviceAddress: {
            addressLine: serViceAddressLine,
            cityName: serviceCityName,
            stateOrProvince: serviceStateOrProvince,
            postalCode: servicePostalCode,
            country: "",
          },
        };
        const updatedCustomerInfoCTF = {
          accountNumber: accountNumber,
          addressId: addressDetails?.addressId,
          firstName: firstName,
          lastName: lastName,
        };
        const updatedAddressInfoPayload = {
          timeZone: timeZone,
          billingAddress: {
            addressLine: addressLine,
            cityName: cityName,
            stateOrProvince: stateOrProvince,
            postalCode: postalCode,
            country: "",
          },
          rateCentre: rateCenter,
        };
        const updateAddressInfoPayloadLegacy = {
          SAMControlNo: samControlNumber,
          environment: env,
          billingAddress: {
            addressLine: addressLine,
            cityName: cityName,
            stateOrProvince: stateOrProvince,
            postalCode: postalCode,
            country: "",
          },
          serviceAddress: {
            addressLine: serViceAddressLine,
            cityName: serviceCityName,
            stateOrProvince: serviceStateOrProvince,
            postalCode: servicePostalCode,
            country: "",
          },
        };
        const updatedCustomerInfoPayload = {
          env: env,
          accountNumber: accountNumber,
          addressId: addressDetails?.addressId,
          uuid: accountUuid,
          usi: usi,
          firstName: firstName,
        };
        const updatedCustomerInfoPayloadLegacy = {
          btn: btn,
          usi: usi,
          env: env,
          firstName: firstName,
          lastName: lastName,
        };
        setSubmitOrderPayload({
          ...submitOrderPayload,
          addressInfo: copperToFiber
            ? updatedAddressInfoCTF
            : isNDS
            ? updatedAddressInfoPayload
            : updateAddressInfoPayloadLegacy,
          customerInfo: copperToFiber
            ? updatedCustomerInfoCTF
            : isNDS
            ? updatedCustomerInfoPayload
            : updatedCustomerInfoPayloadLegacy,
          lastName: lastName,
          sessionId: addressDetails?.sessionId,
        });
      },
      onError: () => dispatch(setCustomerPlanDetailsError(true)),
      fetchPolicy: "network-only",
    });

  const [getAddOndsForFuturePlan, { loading: addOnsLoading, error: getAddOndsForFuturePlanError }] =
    useMutation(GET_ADDONS_FOR_FUTURE_PLAN, {
      onError: () => setFutureAddOnPlanErr(true),
      onCompleted: (data: any) => {
        setFutureAddOnPlanErr(false);
        handleContentBySkus(data);
      },
    });

  const [submitOrderLegacy, { loading: submitOrderLegacyLoading }] = useMutation(
    SUBMIT_ORDER_LEGACY,
    {
      onError: (error: any) => {
        const errorObj = JSON.parse(error.networkError.result.errors[0]?.message);
        const CXPOrderId = errorObj?.content?.error?.CXPOrderId;
        const { dpiOrderId } = errorObj?.content?.error?.openOrder;
        if (CXPOrderId || dpiOrderId || error.networkError.result.errors[0]?.code === "409") {
          setSubmitOrderErr(errorObj?.content?.error);
          if (!install) {
            handleFallout(errorObj?.content?.error);
          } else {
            handleStepChange(1);
          }
        } else {
          setSubmitOrderLegacyErr(true);
        }
      },
    }
  );

  const address = parseAddress(serviceAddress ?? "");

  const handleSeeMore = () => {
    setShowMore((prev) => !prev);
  };
  const handleAvailablePlans = async (data: customerPlanDetailsProps) => {
    dispatch(setCustomerPlanDetailsError(false));
    try {
      const response = await getAvailablePlans({
        variables: {
          addressId: data?.addressId ?? "",
          currentPlanSpeed: data?.planSpeed ?? "",
          itemCode: data?.itemCode ?? "",
          subsidyName: data?.subsidyName ?? "",
          uuid: data?.uuid ?? "",
          usi: usi ?? "",
          sessionId: data?.sessionId ?? "",
          isNds: isNDS,
          copperToFiber: copperToFiber,
          fromBanner: fromBanner,
        },
      });
      if (!response?.data?.getAvailablePlansForUpgrade?.error) {
        const data = response.data?.getAvailablePlansForUpgrade;
        if (isNDS || copperToFiber) {
          data?.availablePlans?.forEach((plan: any) => {
            if (plan?.discounts?.length > 0) {
              const discountAmount = parseFloat(plan?.discounts[0]?.price);
              const originalPrice = parseFloat(plan.price);
              const priceAfterDiscount = originalPrice + discountAmount;
              plan.priceAfterDiscount = priceAfterDiscount?.toString();
            }
          });
        }
        setAvailablePlansData(data?.availablePlans);
        setHighestPlan(response?.data?.getAvailablePlansForUpgrade?.highestPlanName);
        dispatch(setAvailablePlansError(false));
      } else if (response?.data?.getAvailablePlansForUpgrade?.error) {
        dispatch(setAvailablePlansError(true));
      }
    } catch (err) {
      dispatch(setAvailablePlansError(true));
      console.log(err);
    }
  };

  const existingPlanDetails = customerPlanDetails?.getCustomerPlanDetails;

  const [
    getAvailablePlans,
    { error: availablePlansError, loading: availablePlansloading, data: availablePlans },
  ] = useMutation(GET_AVAILABLE_PLANS_FOR_UPGRADE, {
    fetchPolicy: "network-only",
  });

  const availablePlanData = availablePlans?.getAvailablePlansForUpgrade;

  const handleGetFutureAddOns = async () => {
    let promotions = existingPlanDetails?.promotions;
    promotions =
      promotions &&
      promotions?.length > 0 &&
      promotions.find((promotion: any) => promotion?.promotionSE?.includes("#"));
    try {
      const response = await getAddOndsForFuturePlan({
        variables: {
          addonInputForFuturePlan:
            copperToFiber || isNDS
              ? {
                  uuid: existingPlanDetails?.uuid ?? "",
                  futurePlanSE: selectedPlanDetails?.se ?? "",
                  futurePlanProductId: selectedPlanDetails?.productId ?? "",
                  futurePlanSKU: selectedPlanDetails?.sku ?? "",
                  ONTMaxSpeed: availablePlanData?.currentONTMaxSpeed ?? "",
                  currentPlanTechnology: availablePlanData?.currentTechnology ?? "",
                  currentPlanSKU: availablePlanData?.currentSku ?? "",
                  subsidyName: existingPlanDetails?.subsidyName ?? "",
                  sessionId: existingPlanDetails?.sessionId ?? "",
                  offerId: CTFOfferId ?? "",
                  currentPlanProductId: existingPlanDetails?.itemCode ?? "",
                }
              : {
                  uuid: existingPlanDetails?.uuid ?? "",
                  futurePlanSE: selectedPlanDetails?.se ?? "",
                  futurePlanProductId: selectedPlanDetails?.productId ?? "",
                  futurePlanSKU: selectedPlanDetails?.sku ?? "",
                  ONTMaxSpeed: availablePlanData?.currentONTMaxSpeed ?? "",
                  currentPlanTechnology: availablePlanData?.currentTechnology ?? "",
                  currentPlanSKU: availablePlanData?.currentSku ?? "",
                  subsidyName: existingPlanDetails?.subsidyName ?? "",
                  sessionId: existingPlanDetails?.sessionId ?? "",
                  promotionSE: promotions?.promotionSE ?? "",
                  promotionStartDate: promotions?.promotionStartDate ?? "",
                  offerId: CTFOfferId ?? "",
                  currentPlanProductId: existingPlanDetails?.itemCode ?? "",
                },
          isNds: isNDS,
        },
      });
      const result = response?.data?.getAddonsForFuturePlan;
      promotions = result?.promotions?.filter(
        (item: any) => item?.startDate && item?.price != 0 && item?.expired?.toLowerCase() !== "y"
      );
      setPromotion(promotions);
      if (!result?.error && result?.newAddons) {
        result?.newAddons?.forEach((addon: any) => {
          if (
            addon?.discounts?.length > 0 &&
            addon?.productId !== "WHW" &&
            !isNDS &&
            !copperToFiber
          ) {
            // Check if the discounts array is not empty
            const discountAmount = parseFloat(addon?.discounts[0]?.price);

            const originalPrice = parseFloat(addon.price);
            const priceAfterDiscount = originalPrice + discountAmount;

            // Add the priceAfterDiscount key to the plan object
            addon.priceAfterDiscount = priceAfterDiscount?.toString();
          }
        });

        setFutureAddOnPlanData(result);
      }
    } catch (err) {
      setFutureAddOnPlanErr(true);
      console.log(err);
    }
  };

  const [getNutritionLabelByPlan, { loading: nutritionByPlanLoading, data: nutritionByPlanData }] =
    useMutation(NUTRITION_LABEL_BY_PLAN_DETAILS, {
      variables: {
        uuid: existingPlanDetails?.uuid,
        sessionId: existingPlanDetails?.sessionId,
        planDetails: {
          id: seId,
        },
      },
      fetchPolicy: "network-only",
      onError: () => {
        dispatch(setNutritionLabelbyPlanError(true));
      },
      onCompleted: () => {
        dispatch(setNutritionLabelbyPlanError(false));
        dispatch(setZoomOutFlag(true));
      },
    });

  const [getAddonContentBySku, { error: addOnContentErr, loading: addOnContentLoading }] =
    useMutation(GET_ADDON_DETAILS_BY_SKU, { fetchPolicy: "network-only" });

  const handleContentBySkus = async (data: any) => {
    const filterFiberPrice =
      data?.getAddonsForFuturePlan?.newAddons &&
      data?.getAddonsForFuturePlan?.newAddons?.filter(
        (data: any) => data?.groupType === "Router" && data?.productId !== "BYO_ROUTER"
      );
    const groupedAddons =
      data?.getAddonsForFuturePlan?.newAddons &&
      data?.getAddonsForFuturePlan?.newAddons?.reduce((acc: any, addon: any) => {
        const existingGroup = acc.find((group: any) => group.groupType === addon.groupType);
        if (existingGroup) {
          existingGroup.items.push(addon);
        } else {
          acc.push({
            groupType: addon.groupType,
            items: [addon],
          });
        }
        return acc;
      }, []);
    const fiberRouterSKU = data?.getAddonsForFuturePlan?.fiberRouterSKU;
    const addonSkus =
      groupedAddons?.flatMap((group: any) => group?.items?.map((item: any) => item?.sku)) || [];
    const skus = fiberRouterSKU?.length > 0 ? [...addonSkus, fiberRouterSKU] : addonSkus;

    try {
      const response = await getAddonContentBySku({
        variables: {
          skuIds: skus,
          fiberRouterPrice: filterFiberPrice?.[0]?.price?.toString(),
        },
      });
      if (!response?.data?.getAddonDetailsContentBySkuIds?.error) {
        setAddonContent(response?.data?.getAddonDetailsContentBySkuIds?.addonsContent);
        setAddonsForFuturePlanError(false);
      } else {
        setAddonsForFuturePlanError(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const { phoneNumberValidation } = usePhoneNumberValidation(
    phoneNumber,
    setVerifyInfoErrors,
    verifyInfoErrors
  );

  const validatePhoneNumber = async () => {
    try {
      const response = await phoneNumberValidation();
      if (response?.data) {
        if (response?.data?.phoneNumberValidation?.error) {
          setVerifyInfoErrors({
            validatePhoneNumberError: response?.data?.phoneNumberValidation?.error,
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handlePhoneNumberBlur = () => {
    if (phoneNumber !== "") {
      validatePhoneNumber();
    }
    handleValidation("phoneNumber");
  };

  const handleStepChange = (increment: number) => {
    setActiveStep((currentStep) => {
      const nextStep = currentStep + increment;

      if (nextStep < 1 || nextStep > 4) {
        return currentStep;
      }

      switch (nextStep) {
        case 1:
          setStepperHeading("Select New Speed");
          break;
        case 2:
          setStepperHeading("Select Equipment");
          break;
        case 3:
          setStepperHeading("Verify Personal Info");
          break;
        case 4:
          setStepperHeading("Confirm and Submit");
          break;
        default:
          setStepperHeading("Select New Speed");
          break;
      }

      return nextStep;
    });
  };

  const flagData = paperLessData?.paperlessFlag === true ? false : true;

  const [setPaperless, { loading: setPaperLessLoading }] = useMutation(SET_PAPERLESS, {
    refetchQueries: [AUTOPAY_PAPERLESS_DETAILS],
    onError: () => {
      setCheckPaperless(false);
      if (enableAutopayPaperless && !(autoPayData && Object.keys(autoPayData)?.length > 0)) {
        setOpenAutopay(true);
      }
      setPaperlessError(true);
    },
    onCompleted: () => {
      if (enableAutopayPaperless && !(autoPayData && Object.keys(autoPayData)?.length > 0)) {
        setOpenAutopay(true);
      }
    },
  });

  const handlePaperless = async () => {
    try {
      const response = await setPaperless({
        variables: {
          accountNumber: accountNumber,
          paperlessFlag: flagData,
        },
      });
      if (response?.data?.setPaperless?.error) {
        if (enableAutopayPaperless) {
          setOpenAutopay(true);
        } else {
          setSuccessMessage(false);
          handleSubmitOrder();
        }
      } else {
        if (!response?.data?.setPaperless?.error) {
          const paperLessData = { paperlessFlag: flagData };
          dispatch(paperLessTurnedOn(paperLessData));
          setSuccessMessage(true);
          setUserStatus(TURNON_PAPERLESS_SUCCESS_MESSAGE);
          if (checkPaperless && checkAutopay) {
            setEnableAutopayPaperless(true);
            setOpenAutopay(true);
            setCheckPaperless(false);
            return;
          }
          if (checkPaperless && !checkAutopay && !enableAutopayPaperless) {
            updateSubmitOrderPayloadVerify();
            handleSubmitOrder();
            setCheckPaperless(false);
          } else {
            handleSubmitOrder();
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  const handleValidateInfoAndProceed = () => {
    let errors = validInputSubmit({ phoneNumber });
    if (Object.keys(errors).length > 0) {
      setVerifyInfoErrors(errors);
    }

    const IsEmpty = Object.values(errors).every((value) => value === "");

    // On click of next button
    if (IsEmpty && !checkAutopay && !checkPaperless) {
      // Go to next step
      updateSubmitOrderPayloadVerify();
      handleSubmitOrder();
    } else if (IsEmpty) {
      if (checkPaperless && !checkAutopay) {
        // Call paperless, on success go to next step
        handlePaperless();
      } else if (
        !checkPaperless &&
        checkAutopay &&
        !(autoPayData && Object.keys(autoPayData)?.length > 0)
      ) {
        //open sign up autopay in same screen
        setOpenAutopay(true);
      } else if (
        !checkPaperless &&
        checkAutopay &&
        autoPayData &&
        Object.keys(autoPayData)?.length > 0
      ) {
        // Do not open the autopay modal, proceed to the next step
        updateSubmitOrderPayloadVerify();
        handleSubmitOrder();
      } else if (checkPaperless && checkAutopay) {
        //If both autopayData and paperlessData are already present, proceed directly to the next step
        if (
          autoPayData &&
          Object.keys(autoPayData)?.length > 0 &&
          paperLessData &&
          Object.keys(paperLessData)?.length > 0
        ) {
          // Go directly to the next step
          updateSubmitOrderPayloadVerify();
          handleSubmitOrder();
        } else {
          // First call paperless API, on success go to signup autopay screen
          setEnableAutopayPaperless(true);
          handlePaperless();
        }
      }
    }
  };

  const handleNextStep = () => {
    window.scrollTo(0, 0);
    ZoomOutFlag && dispatch(setZoomOutFlag(false));
    if (stepperHeading === "Select New Speed" && activeStep === 1) {
      handleNext();
      handleGetFutureAddOns();
      dispatch(setNutritionLabelbyPlanError(false));
    }
    if (stepperHeading === "Confirm and Submit" && activeStep === 4) {
      if (submitOrderResponse?.appointments && submitOrderResponse?.appointments?.length > 0) {
        if (copperToFiber || isNDS) {
          handleCommitOrderNDS();
        } else {
          handleCommitOrderLegacy();
        }
      } else {
        handleFallout();
      }
    }
    if (stepperHeading === "Select Equipment" && activeStep === 2) {
      if (addPhone) {
        let newPhoneErrors: any = {};
        let portErrors: any = {};
        if (selectPhoneVal === "newPhone") {
          newPhoneErrors = validInputSubmit(listingInfo);
          setNewPhoneErrors(newPhoneErrors);
        } else if (selectPhoneVal === "existingPhone") {
          newPhoneErrors = validInputSubmit(listingInfo);
          portErrors = validInputSubmit(portInfo);
          setNewPhoneErrors(newPhoneErrors);
          setPortErrors(portErrors);
        }
        const hasErrors = [...Object.values(newPhoneErrors), ...Object.values(portErrors)].some(
          (error) => error !== ""
        );

        if (hasErrors) {
          return;
        }
      } else {
        updateSubmitOrderPayload();
      }
    }

    if (stepperHeading === "Verify Personal Info" && activeStep === 3) {
      if (autopaySuccess) {
        setOpenAutopay(false);
        handleSubmitOrder();
        return;
      } else if (openAutopay) {
        handleStepChange(1);
      } else {
        handleValidateInfoAndProceed();
      }
    } else {
      handleStepChange(1);
    }
  };
  const handlePreviousStep = () => {
    window.scrollTo(0, 0);
    if (futureAddOnPlanErr) setFutureAddOnPlanErr(false);
    if (AddonsForFuturePlanError) setAddonsForFuturePlanError(false);

    if (stepperHeading === "Select Equipment" && activeStep === 2) {
      setSubmitOrderArr([]);
      setSelectedFiber({});
      setFutureRouterArray([]);
      dispatch(setIsSelected(false));
      setSelectPlan(null);
      setShowBroadbandFacts(false);
      setAddPhone(false);
      setShowRouterType("");
      setUpdateRouterType(null);
      setFutureAddOnPlanData({});
      setSelectedPhoneItem({});
      setCheckAutopay(false);
      setCheckPaperless(false);
    }
    if (stepperHeading === "Confirm and Submit" && activeStep === 4 && autopaySuccess) {
      setOpenAutopay(false);
      handleStepChange(3);
    }
    if (stepperHeading === "Verify Personal Info" && activeStep === 3) {
      if (autoPayData && Object.keys(autoPayData)?.length > 0) {
        setCheckAutopay(false);
      }
      dispatch(setAutopaySuccess(false));
      openAutopay ? setOpenAutopay(false) : handleStepChange(-1);
      dispatch(setShowNextinAutoPay(false));
      if (enableAutopayPaperless) {
        setEnableAutopayPaperless(false);
      }
    } else if (checkAutopay && checkPaperless) {
      handleStepChange(-1);
    } else {
      handleStepChange(-1);
    }
  };

  const handleGetPlanDetails = async () => {
    try {
      const data = linkedAccountNumbers?.filter((acc: any) => acc.accountNumber === accountNumber);
      await getCustomerPlanDetails({
        variables: {
          accountNumber: accountNumber,
          isNds: data[0]?.isNDS,
          customerAddress: address,
          accountUuid: accountUuid,
        },
      });
    } catch (err) {
      console.log("error while fetching plan details", err);
    }
  };

  const [submitOrder, { loading: submitOrderLoading }] = useMutation(SUBMIT_ORDER_UPGRADE, {
    onError: (error: any) => {
      const errorObj = JSON.parse(error.networkError.result.errors[0]?.message);
      const { dpiOrderId, CXPOrderId } = errorObj?.content?.error;
      if (CXPOrderId || dpiOrderId || error.networkError.result.errors[0]?.code === "409") {
        setSubmitOrderErr(errorObj?.content?.error);
        if (!install) {
          handleFallout(errorObj?.content?.error);
        } else {
          handleStepChange(1);
        }
      } else {
        setSubmitOrderFail(true);
      }
    },
  });

  const [commitOrder, { loading: commitOrderLoading }] = useMutation(COMMIT_ORDER, {
    onError: () => handleFallout(),
  });

  const [falloutAPI, { loading: falloutLoading }] = useMutation(FALLOUT_ORDER);

  // onclick of next after selecting new plan
  const handleNext = () => {
    let discountItems: any = [];
    if (selectedPlanDetails?.discounts.length > 0) {
      selectedPlanDetails.discounts.map((item: any) => {
        let discount = {
          discountCode: item.discountCode,
          duration: item.duration,
          unitOfMeasure: item.unit,
          price: {
            discountAmount: item.price,
          },
          discountBillDescription: item.discountBillDescription,
        };
        discountItems.push(discount);
      });
    }
    setSubmitOrderPayload({
      ...submitOrderPayload,
      addressInfo: {
        ...submitOrderPayload.addressInfo,
        ...((copperToFiber || ndsObj?.isNDS) && {
          media: selectedPlanDetails?.selectedPlanMedia,
          technology: selectedPlanDetails?.selectedPlanTechnology,
          fiberDrop: selectedPlanDetails?.fiberDrop,
          terminalType: selectedPlanDetails?.selectedPlanTerminalType,
        }),
      },
      addItemBasePackage: {
        productId: selectedPlanDetails?.productId,
        serviceId: "",
        baseprice: selectedPlanDetails?.price,
        discountItems: discountItems,
      },
    });
  };

  //should call this function after input validations
  const updateSubmitOrderPayload = () => {
    let updatedPayload = selectedPhoneItem?.productId
      ? {
          portIn: selectPhoneVal === "existingPhone" ? true : false,
          firstName: listingInfo?.firstName,
          lastName: listingInfo?.lastName,
          directoryAddress: listingInfo?.address,
          directoryCity: listingInfo?.city,
          directoryState: listingInfo?.state,
          directoryZip: listingInfo?.zip,
          phoneBookCopies: 0,
          voicemailPIN: Number(listingInfo.voicemail),
        }
      : {};
    setSubmitOrderPayload({
      ...submitOrderPayload,
      newNumberInfo: updatedPayload,
    });
  };

  //call on clicking of next on verify info
  const updateSubmitOrderPayloadVerify = () => {
    const formattedPhoneNumber =
      copperToFiber || !isNDS ? formatPhoneNumber(phoneNumber) : phoneNumber;
    let updatedPayload = {
      ...submitOrderPayload,
      contactInfo: {
        primaryContactNumber: formattedPhoneNumber,
        CBR: formattedPhoneNumber,
        email: primaryEmail ?? email,
      },
    };
    setSubmitOrderPayload(updatedPayload);
  };

  const handleSubmitOrder = async () => {
    const formattedPhoneNumber =
      copperToFiber || !isNDS ? formatPhoneNumber(phoneNumber) : phoneNumber;
    const contactInfo = {
      primaryContactNumber: formattedPhoneNumber,
      CBR: formattedPhoneNumber,
      email: primaryEmail ?? email,
    };
    //we are creating addons that we have to send BE
    let newAddonsArray: any = [...futureRouterArray];
    futureRouterArray?.forEach((item: any) => {
      if (item?.groupType === "Voice Addon" && (copperToFiber || isNDS)) {
        item?.split_productId.forEach((splitId: any) => {
          const matchedItem = dependencyPrerequisite?.find(
            (dataItem: any) => dataItem.productId === splitId
          );
          if (matchedItem) {
            newAddonsArray.push(matchedItem);
          }
        });
      }
      if (item?.groupType === "Equipment") {
        item?.prerequisite_sku?.forEach((splitId: any) => {
          const matchedItem = dependencyPrerequisite?.find(
            (dataItem: any) => dataItem.sku === splitId
          );
          if (matchedItem) {
            newAddonsArray.push(matchedItem);
          }
        });
      }
    });

    //futureRouterArray is the array which we have to pass Be for addons
    setFutureRouterArray(newAddonsArray);
    let newAddonItemArray = removeMatchedAddons(newAddonsArray, existingPlanDetails?.addons, isNDS);
    // check for instalaltion and send to api
    let findNewRouter = newAddonItemArray?.find(
      (item: any) => item?.groupType === "Router" && item?.productId !== "BYO_ROUTER"
    );
    if (
      (findNewRouter && (copperToFiber || isNDS)) ||
      selectedPlanDetails?.productId === "FIBER_10G" ||
      addPhone
    ) {
      futureAddOnPlanData?.oneTimeFee &&
        futureAddOnPlanData?.oneTimeFee?.length > 0 &&
        newAddonItemArray.push(futureAddOnPlanData?.oneTimeFee?.[0]);
    }
    if (findNewRouter || addPhone) {
      setInstall(true);
    } else {
      setInstall(false);
    }

    const addons = transformAddons(newAddonItemArray, isNDS || copperToFiber);
    // addons for legacy customers
    const addonsLegacy = transformAddonsLegacy(newAddonItemArray);
    //filter out autopay and paperless from existing addons - NDS
    const filterAutopayPaperless = existingPlanDetails?.addons.filter(
      (item: any) => item.addonItemCode !== "PAPERINV_FEE" && item.addonItemCode !== "PYMTCONV_FEE"
    );
    //getNoChangeAddons variable will be send to the BE for noChnageAddons
    const getNoChangeAddons = findMatchingItemOrderSubmit(filterAutopayPaperless, newAddonsArray);

    const noChangeTransformAdons =
      getNoChangeAddons && getNoChangeAddons?.length > 0
        ? transformAddons(getNoChangeAddons, isNDS || copperToFiber)
        : [];
    //removed addons will be in existing plan , and we have to compare with  newAddonsArray
    const removedAddonsArray = findRemovedAddons(filterAutopayPaperless, newAddonsArray, isNDS);
    //filter out autopay and paperless from existing addons - Legacy
    const filterAutopayPaperlessLegacy = existingPlanDetails?.addons.filter(
      (item: any) => item.addonItemCode !== "PAPBR" && item.addonItemCode !== "PAYR1"
    );
    // removed addons for NDS
    const removedAddonsMaped = transformRemovedAddons(removedAddonsArray, isNDS || copperToFiber);
    //removed addons for legacy
    const removedLegacyAddonsArray = findRemovedAddons(
      filterAutopayPaperlessLegacy,
      newAddonsArray,
      isNDS
    );
    const removedAddonsMapedLegacy = transformRemovedAddonsLegacy(removedLegacyAddonsArray);

    const previousBasePackage = {
      productId: existingPlanDetails?.itemCode,
      serviceId: existingPlanDetails?.serviceId ?? "",
      baseprice: existingPlanDetails?.basePrice,
    };
    // base previous package legacy
    const legacyBasePlan = {
      id: existingPlanDetails?.itemCode,
      sku: existingPlanDetails?.sku,
      LISno: existingPlanDetails?.LISno,
    };
    // base package future
    const legacyBasePackageFuture = {
      sku: selectedPlanDetails?.sku,
      id: selectedPlanDetails?.se,
    };

    ///cal api submit Api
    //promotions for legacy customers
    const promotionLegacyFuture =
      futureAddOnPlanData?.promotions && futureAddOnPlanData?.promotions?.length > 0
        ? futureAddOnPlanData?.promotions
            ?.filter(
              (item: any) =>
                item?.startDate && item?.price != 0 && item?.expired?.toLowerCase() !== "y"
            )
            ?.map((item: any) => ({
              id: item?.SE,
              startDate: item?.startDate,
            }))
        : [];

    const promotionLegacyExisting =
      promotionLegacyFuture && promotionLegacyFuture.length > 0
        ? []
        : existingPlanDetails?.promotions.map((item: any) => ({
            id: item?.promotionSE,
            LISno: item?.LISno,
          }));

    if (copperToFiber || ndsObj?.isNDS) {
      try {
        const result = await submitOrder({
          variables: {
            accountNumber: accountNumber,
            uuid: existingPlanDetails?.uuid,
            sessionId: existingPlanDetails?.sessionId,
            isNds: copperToFiber === true ? true : ndsObj?.isNDS,
            subsidyName: existingPlanDetails?.subsidyName ?? "",
            submitOrderInput: {
              contactInfo: contactInfo,
              addressInfo: submitOrderPayload?.addressInfo,
              customerInfo: submitOrderPayload?.customerInfo,
              ...(copperToFiber === true && {
                legacyCopperCustomerInfo: {
                  firstName: firstName,
                  lastName: lastName,
                  isLegacyCopperCustomer: copperToFiber,
                  accountName: `${firstName} ${lastName}`,
                  accountNumber,
                  customerSince: primaryEmailData?.getPrimaryEmailByAccountUuid?.customerSince,
                  customerPin: pin,
                  autoPay: autoPayData && Object.keys(autoPayData)?.length === 0 ? "No" : "Yes",
                  paperless: paperLessData?.paperlessFlag === false ? "No" : "Yes",
                  primaryContactId: primaryEmailData?.getPrimaryEmailByAccountUuid?.contactId,
                  uuid: accountUuid,
                  usi,
                  hasExistingVoice: isAlreadyPhoneUnlimited === "alreadyAdded" ? true : false,
                },
              }),
              ndsAddItems: {
                basePackage: submitOrderPayload?.addItemBasePackage,
                addons: addons,
              },
              ndsNoChangeItems: {
                ...(copperToFiber === true ? {} : { addons: noChangeTransformAdons }),
              },
              ndsRemoveItems: {
                basePackage: previousBasePackage,
                addons: removedAddonsMaped?.length > 0 ? removedAddonsMaped : [],
              },
              ...(addPhone && {
                newNumberInfo: {
                  portIn: selectPhoneVal === "existingPhone" ? true : false,
                  firstName: listingInfo.firstName,
                  lastName: listingInfo.lastName,
                  directoryAddress: listingInfo.address,
                  directoryCity: listingInfo.city,
                  directoryState: listingInfo.state,
                  directoryZip: listingInfo.zipcode,
                  phoneBookCopies: 0,
                  voicemailPIN: Number(listingInfo.voicemail),
                },
              }),
              ...(selectPhoneVal === "existingPhone" && {
                portinInfo: {
                  existingAccountNumber: portInfo.accountNumber,
                  existingPin: portInfo.existingPin,
                  existingTelecomAddress: portInfo.existingTelecomAddress,
                  portInTelecomAccountNumber: portInfo.portInNumber,
                },
              }),
              ontMaxSpeed: availablePlanData?.currentONTMaxSpeed,
              newFiberBillInfo: {
                ...(copperToFiber && { billCycle: "1" }),
                firstBillTotalAmount: 0,
                estimatedMonthlyTotalAmount:
                  orderTotalPrice?.totalPrice + orderTotalPrice?.extimatedTax,
              },
            },
          },
        });
        if (!result?.data?.submitUpgradeOrder?.error) {
          setOrderNumber(result?.data?.submitUpgradeOrder?.dpiOrderId);
          if (
            result?.data?.submitUpgradeOrder?.appointmentError == null &&
            !result?.data?.submitUpgradeOrder?.appointments &&
            (result?.data?.submitUpgradeOrder?.orderStatus == "commited" ||
              result?.data?.submitUpgradeOrder?.orderStatus == "committed" ||
              result?.data?.submitUpgradeOrder?.orderStatus == "reserve_failed" ||
              result?.data?.submitUpgradeOrder?.orderStatus == "create_failed")
          ) {
            setSuccessScreen(true);
            getorderDetails();
          } else {
            setSubmitOrderResponse(result?.data?.submitUpgradeOrder);
            // go to next step with enabling no of appointments given by api, call commit order API with selected slot, if commit order fails, we need to call fallout API with the selected slot
            handleStepChange(1);
          }
        }
      } catch (error: any) {
        //error
        console.log(error);
      }
    } else {
      // submit order api for non nds
      try {
        const result = await submitOrderLegacy({
          variables: {
            accountNumber: accountNumber,
            uuid: existingPlanDetails?.uuid,
            sessionId: existingPlanDetails?.sessionId,
            isNds: isNDS,
            subsidyName: existingPlanDetails?.subsidyName ?? "",
            submitOrderInput: {
              contactInfo: contactInfo,
              addressInfo: submitOrderPayload?.addressInfo,
              customerInfo: submitOrderPayload.customerInfo,
              addItems: {
                basePackage: legacyBasePackageFuture,
                addons: addonsLegacy,
                promotions: promotionLegacyFuture,
              },
              removeItems: {
                basePackage: legacyBasePlan,
                addons: removedAddonsMapedLegacy?.length > 0 ? removedAddonsMapedLegacy : [],
                promotions: promotionLegacyExisting,
              },
              ontMaxSpeed: availablePlanData?.currentONTMaxSpeed,
            },
          },
        });
        if (!result?.data?.submitUpgradeOrder?.error) {
          setOrderNumber(result?.data?.submitUpgradeOrder?.dpiOrderId);
          if (
            result?.data?.submitUpgradeOrder?.appointmentError == null &&
            !result?.data?.submitUpgradeOrder?.appointments &&
            (result?.data?.submitUpgradeOrder?.orderStatus == "commited" ||
              result?.data?.submitUpgradeOrder?.orderStatus == "committed" ||
              result?.data?.submitUpgradeOrder?.orderStatus == "reserve_failed" ||
              result?.data?.submitUpgradeOrder?.orderStatus == "create_failed")
          ) {
            setSuccessScreen(true);
            getorderDetails();
          } else {
            setSubmitOrderResponse(result?.data?.submitUpgradeOrder);
            // go to next step with enabling no of appointments given by api, call commit order API with selected slot, if commit order fails, we need to call fallout API with the selected slot
            handleStepChange(1);
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
  };
  const handleCommitOrderNDS = async () => {
    try {
      const result = await commitOrder({
        variables: {
          accountNumber: accountNumber,
          isNds: copperToFiber === true ? true : isNDS,
          uuid: submitOrderPayload?.customerInfo?.uuid,
          sessionId: submitOrderPayload?.sessionId,
          commitOrderInput: {
            dpiOrderId: submitOrderResponse?.dpiOrderId ?? "",
            selectedAppointment: {
              startDate: selectedSlotObj?.startDate,
              endDate: selectedSlotObj?.endDate,
              workUnitsQuantity: selectedSlotObj?.workUnitsQuantity,
              contactFullName: `${submitOrderPayload?.customerInfo?.firstName} ${submitOrderPayload?.lastName}`,
              primaryContactNumber: extractPhoneNumber(submitOrderPayload.contactInfo.CBR),
              secondaryContactNumber: extractPhoneNumber(submitOrderPayload.contactInfo.CBR),
            },
          },
        },
      });
      if (!result?.data?.commitOrderUpgrade?.error) {
        setSuccessScreen(true);
        getorderDetails();
      } else {
        handleFallout();
      }
    } catch {
      handleFallout();
    }
  };

  const handleCommitOrderLegacy = async () => {
    try {
      const result = await commitOrder({
        variables: {
          accountNumber: accountNumber,
          isNds: isNDS,
          uuid: submitOrderPayload?.customerInfo?.uuid,
          sessionId: submitOrderPayload?.sessionId,
          commitOrderInput: {
            primaryTelephone: formatPhoneNumber(submitOrderPayload.contactInfo.CBR),
            secondaryTelephone: formatPhoneNumber(submitOrderPayload.contactInfo.CBR),
            dpiOrderId: submitOrderResponse?.dpiOrderId ?? "",
            selectedAppointment: {
              startDate: selectedSlotObj?.startDate,
              endDate: selectedSlotObj?.endDate,
              resourcePoolId: selectedSlotObj?.resourcePoolId,
              timeSlotType: selectedSlotObj?.timeSlotType,
              workUnitsQuantity: selectedSlotObj?.workUnitsQuantity,
            },
          },
        },
      });
      if (!result?.data?.commitOrderUpgrade?.error) {
        setSuccessScreen(true);
      } else {
        handleFallout();
      }
    } catch {
      handleFallout();
    }
  };

  const handleFallout = async (data?: any) => {
    try {
      const contactInfo = {
        primaryContactNumber:
          copperToFiber || !isNDS ? formatPhoneNumber(phoneNumber) : phoneNumber,
        CBR: copperToFiber || !isNDS ? formatPhoneNumber(phoneNumber) : phoneNumber,
        email: primaryEmail ?? email,
      };
      const response = await falloutAPI({
        variables: !isNDS
          ? {
              accountNumber: accountNumber,
              fallOutOrderInput: {
                CXPOrderId: !install
                  ? data?.CXPOrderId?.toString()
                  : submitOrderErr?.CXPOrderId?.toString() ??
                    submitOrderResponse?.CXPOrderId?.toString() ??
                    "",
                dpiOrderId: !install
                  ? data?.openOrder?.dpiOrderId
                  : submitOrderErr?.openOrder?.dpiOrderId?.toString() ??
                    submitOrderResponse?.dpiOrderId ??
                    "",
                email: primaryEmail ?? emailContact?.email,
                name: `${firstName} ${lastName}`,
                isNDS: isNDS,
                ...(install && {
                  selectedAppointment: {
                    startDate: selectedSlotObj.startDate,
                    endDate: selectedSlotObj.endDate,
                    contactFullName: `${firstName} ${lastName}`,
                    primaryContactNumber: contactInfo?.primaryContactNumber,
                    secondaryContactNumber: contactInfo?.CBR,
                  },
                }),
              },
            }
          : {
              accountNumber: accountNumber,
              fallOutOrderInput: {
                CXPOrderId: !install
                  ? data?.CXPOrderId?.toString()
                  : submitOrderErr?.CXPOrderId?.toString() ??
                    submitOrderResponse?.CXPOrderId?.toString() ??
                    "",
                dpiOrderId: !install
                  ? data?.dpiOrderId
                  : submitOrderErr?.dpiOrderId?.toString() ?? submitOrderResponse?.dpiOrderId ?? "",
                email: primaryEmail ?? emailContact?.email,
                name: `${firstName} ${lastName}`,
                isNDS: isNDS,
                ...(install && {
                  selectedAppointment: {
                    startDate: selectedSlotObj.startDate,
                    endDate: selectedSlotObj.endDate,
                    contactFullName: `${firstName} ${lastName}`,
                    primaryContactNumber: contactInfo?.CBR,
                    secondaryContactNumber: contactInfo?.CBR,
                  },
                }),
              },
            },
      });
      if (!response?.data?.falloutOrder?.error) {
        setSuccessScreen(true);
        getorderDetails();
        getPlansServices();
      }
    } catch (err) {
      console.log(err);
      setFalloutErr(true);
    }
  };

  const handleAutopayAndPaperlessErrors = () => {
    if (paperlessError && !autopayFailure) {
      setShowErrorMessage(PAPERLESS_UPGRADE_ORDER_ERROR);
      setSuccessScreen(false);
      setShowErrorScreen(true);
    } else if (!paperlessError && autopayFailure) {
      setShowErrorMessage(AUTOPAY_UPGRADE_ORDER_ERROR);
      setShowErrorScreen(true);
      setSuccessScreen(false);
    } else if (paperlessError && autopayFailure) {
      setShowErrorMessage(AUTOPAY_AND_PAPERLESS_UPGRADE_ORDER_ERROR);
      setShowErrorScreen(true);
      setSuccessScreen(false);
    } else {
      setShowErrorScreen(false);
      navigate("/account");
    }
  };

  const loading =
    submitOrderLoading || submitOrderLegacyLoading || commitOrderLoading || falloutLoading;

  const orderID =
    submitOrderErr?.dpiOrderId ??
    submitOrderErr?.CXPOrderId ??
    submitOrderResponse?.dpiOrderId ??
    submitOrderResponse?.CXPOrderId ??
    orderNumber;

  useEffect(() => {
    if (futureAddOnPlanData && futureAddOnPlanData?.newAddons) {
      const wholeHomeWifi = futureAddOnPlanData?.newAddons?.find(
        (item: any) => item?.name === "Whole Home Wifi"
      );
      const wifiRouter = futureAddOnPlanData?.newAddons?.find(
        (item: any) => item?.name === "WiFi Router"
      );
      if (wholeHomeWifi && wifiRouter) {
        futureAddOnPlanData?.newAddons?.map((item: any) => {
          if (item?.name === "Whole Home Wifi") {
            item.originalPrice = item.price;
            item.price += wifiRouter.price;
          }
        });
      }
      let prerequisiteArray: any = [];

      // Check for Equipment groupType and prerequisite_sku
      futureAddOnPlanData?.newAddons?.forEach((item: any) => {
        if (item.groupType === "Equipment") {
          item.prerequisite_sku.forEach((prerequisite: any) => {
            const matchedItem = futureAddOnPlanData?.newAddons?.find(
              (dataItem: any) => dataItem.sku === prerequisite
            );
            if (matchedItem) {
              prerequisiteArray.push(matchedItem);
            }
          });
        }
      });

      // Check for Voice Addon groupType and split_productId
      futureAddOnPlanData?.newAddons?.forEach((item: any) => {
        if (item.groupType === "Voice Addon") {
          item.split_productId.forEach((splitId: any) => {
            const matchedItem = futureAddOnPlanData?.newAddons?.find(
              (dataItem: any) => dataItem.productId === splitId
            );
            if (matchedItem) {
              prerequisiteArray.push(matchedItem);
            }
          });
        }
      });

      setDependencyPrerequisite(prerequisiteArray);

      const groupedAddons =
        futureAddOnPlanData?.newAddons &&
        futureAddOnPlanData?.newAddons?.reduce((acc: any, addon: any) => {
          const existingGroup = acc.find((group: any) => group.groupType === addon.groupType);
          if (existingGroup) {
            existingGroup.items.push(addon);
          } else {
            acc.push({
              groupType: addon.groupType,
              items: [addon],
            });
          }
          return acc;
        }, []);
      //getting only router grouptypes
      const onlyRouter = groupedAddons.filter((item: any) => item.groupType === "Router");
      //getting only BYO_ROUTER grouptypes
      const codeToCheck = isNDS || copperToFiber ? "BYO_ROUTER" : "BYOEQ";
      const byoRouter = checkAddonItemCode(existingPlanDetails?.addons, codeToCheck);
      setIsByoRouter(byoRouter);
      //matching  productId and addonItemCode  grouptypes
      const findMatchingItemRouterShow = findMatchingItem(
        existingPlanDetails?.addons,
        onlyRouter?.[0]?.items,
        isNDS || copperToFiber
      );
      if (findMatchingItemRouterShow) {
        setIsHavingMatchingRouter(true);
      }
      //adding installing object to conformOrder array
      let installationObj =
        futureAddOnPlanData?.oneTimeFee &&
        futureAddOnPlanData?.oneTimeFee?.length > 0 &&
        futureAddOnPlanData?.oneTimeFee[0];

      // checking is  BYO_ROUTER
      if (byoRouter && !updateRouterType) {
        if (installationObj) {
          setInstallation(installationObj);
        }
        let newRouterDisplayIsByo = onlyRouter?.[0]?.items[0] ?? {};
        newRouterDisplayIsByo.isRouter = true;
        if (newRouterDisplayIsByo.updateToWifi6) {
          newRouterDisplayIsByo.updateToWifi6 = false;
        } else {
          futureRouterArray.push(newRouterDisplayIsByo);
          submitOrderArr.push(newRouterDisplayIsByo);
        }
        setSelectedFiber(newRouterDisplayIsByo);
        setSelectedFiber(newRouterDisplayIsByo);
        setShowRouterType("allRouter");
        //checking is  product Id equal to addonItemCode
      } else if (findMatchingItemRouterShow && !updateRouterType) {
        let wholeHomeWifiAddon = {};
        futureAddOnPlanData?.newAddons?.forEach((item: any) => {
          if (
            item.groupType === "Equipment" ||
            (item.groupType === "Router" && item.productId === "WIFI7_RTR")
          ) {
            if (installationObj && selectedPlanDetails?.productId === "FIBER_10G") {
              setInstallation(installationObj);
            }
            const result = findMatchingAddon(item, existingPlanDetails?.addons, isNDS);
            if (result) {
              wholeHomeWifiAddon = result;
            }
          }
        });
        if (showRouterType !== "updateHomePhone") {
          if (Object.keys(wholeHomeWifiAddon).length > 0) {
            futureRouterArray.push(wholeHomeWifiAddon);
            submitOrderArr.push(wholeHomeWifiAddon);
          }
          setSelectedFiber(findMatchingItemRouterShow);

          futureRouterArray.push(findMatchingItemRouterShow);

          if (Object.keys(wholeHomeWifiAddon).length === 0) {
            submitOrderArr.push(findMatchingItemRouterShow);
          }
        } else {
          //removed previous router
          let replaceRouter = submitOrderArr?.find((item: any) => (item?.isRouter ? true : false));
          if (replaceRouter) {
            let newISwifi = submitOrderArr?.filter((item: any) => !item?.isRouter);
            newISwifi?.push(findMatchingItemRouterShow);
            setSubmitOrderArr([...newISwifi]);
          }
          let replaceFutureRouter = futureRouterArray?.find((item: any) =>
            item?.isRouter ? true : false
          );
          if (replaceFutureRouter) {
            let newISwifiFuture = futureRouterArray?.filter((item: any) => !item?.isRouter);

            newISwifiFuture?.push(findMatchingItemRouterShow);
            setFutureRouterArray([...newISwifiFuture]);
          }
        }

        setShowRouterType("useMyOwnRouter");
        if (!(installationObj && selectedPlanDetails?.productId === "FIBER_10G")) {
          setInstallation({});
        }
        //checking if no match and no  BYO_ROUTER
      } else {
        if (showRouterType === "updateToWifi6") {
          let newRouterDisplay = onlyRouter[0]?.items.map((item: any) => ({
            ...item,
          }));
          if (updateRouterType) {
            newRouterDisplay = onlyRouter[0]?.items?.find(
              (item: any) => item.productId === "WIFI7_RTR"
            );
          }
          newRouterDisplay.isRouter = true;
          newRouterDisplay.updateToWifi6 = true;
          setSelectedFiber(newRouterDisplay);

          if (!updateRouterType) {
            futureRouterArray.push(newRouterDisplay);
            submitOrderArr.push(newRouterDisplay);
          }
        } else {
          setShowRouterType("allRouter");
          if (installationObj) {
            setInstallation(installationObj);
          }
          let routerDisplay =
            futureAddOnPlanData?.newAddons &&
            futureAddOnPlanData?.newAddons?.find((item: any) => item.groupType === "Equipment");

          //checking is  Equipment
          if (routerDisplay) {
            routerDisplay.isRouter = true;
            setSelectedFiber(routerDisplay);

            futureRouterArray.push(routerDisplay);
            submitOrderArr.push(routerDisplay);
            //checking is  other options
          } else {
            let newRouterDisplay = onlyRouter[0]?.items[0] ?? {};
            if (updateRouterType) {
              newRouterDisplay = onlyRouter[0]?.items?.find(
                (item: any) => item.productId !== "WIFI7_RTR"
              );
            }

            newRouterDisplay.isRouter = true;

            if (!updateRouterType) {
              if (showRouterType === "") {
                futureRouterArray?.push(newRouterDisplay);
                submitOrderArr?.push(newRouterDisplay);
              } else {
                newRouterDisplay.updateToWifi6 = false;
              }
            } else {
              let isWifi7 = submitOrderArr?.find((item: any) =>
                item.productId === "WIFI7_RTR" ? true : false
              );
              if (isWifi7) {
                let newISwifi = submitOrderArr?.filter((it: any) => it.productId !== "WIFI7_RTR");
                newISwifi?.push(newRouterDisplay);
                setSubmitOrderArr([...newISwifi]);
              } else {
                submitOrderArr.push(newRouterDisplay);
              }
              let isWifi7Future = futureRouterArray?.find((item: any) =>
                item.productId === "WIFI7_RTR" ? true : false
              );
              if (isWifi7Future) {
                let newISwifiFuture = futureRouterArray?.filter(
                  (it: any) => it.productId !== "WIFI7_RTR"
                );
                newISwifiFuture?.push(newRouterDisplay);
                setFutureRouterArray([...newISwifiFuture]);
              } else {
                futureRouterArray?.push(newRouterDisplay);
              }
            }
            setSelectedFiber(newRouterDisplay);
          }
          setShowRouterType("allRouter");
        }
      }
    }
  }, [futureAddOnPlanData?.newAddons, updateRouterType]);

  useEffect(() => {
    const paperItemCode = "PAPBR";
    const autopayItemCode = "PAYR1";
    let autopay =
      autoPayData !== null &&
      autoPayData &&
      Object.keys(autoPayData)?.length === 0 &&
      (ndsObj?.isNDS || paymentsMigrationToNDS);

    let paperless = paperLessData?.paperlessFlag;
    let autopayPaperlessFee: any = [];
    if (!uuidErrorFlag) {
      if (autopay && paperless === false) {
        const obj1 = {
          name: "Autopay Convenience Fee",
          price: autopayAmount,
          addonItemCode: autopayItemCode,
        };
        const obj2 = {
          name: "Paperless Convenience Fee",
          price: paperlessAmount,
          addonItemCode: paperItemCode,
        };

        autopayPaperlessFee.push(obj1, obj2);
      } else if (autopay) {
        autopayPaperlessFee.push({
          name: "Autopay Convenience Fee",
          price: autopayAmount,
          addonItemCode: autopayItemCode,
        });
      } else if (paperless === false) {
        autopayPaperlessFee.push({
          name: "Paperless Convenience Fee",
          price: paperlessAmount,
          addonItemCode: paperItemCode,
        });
      }
    }
    setSubmitOrderArr((prevState: any) => [...prevState, ...autopayPaperlessFee]);
  }, [futureAddOnPlanData?.newAddons]);

  useEffect(() => {
    if (futureAddOnPlanData?.newAddons) {
      const homePhoneInFuture = futureAddOnPlanData?.newAddons?.find(
        (item: any) => item?.groupType === "Voice Addon"
      );
      const isHomeExists = isNDS
        ? existingPlanDetails?.addons?.find((item: any) => item?.addonItemCode === "VOIP_ADD_HDR")
        : existingPlanDetails?.planDescription?.toLowerCase()?.includes("home phone unlimited");
      setIsHomeInExistingAddon(isHomeExists);
      const matchExistingWithFuture =
        homePhoneInFuture &&
        findMatchingAddon(homePhoneInFuture, existingPlanDetails?.addons, isNDS);
      setIsAlreadyPhoneUnlimited("");
      if (matchExistingWithFuture || (homePhoneInFuture && isHomeExists)) {
        //already subscribed to home phone
        setIsAlreadyPhoneUnlimited("alreadyAdded");
        futureRouterArray.push(isNDS ? matchExistingWithFuture : homePhoneInFuture);
        setSelectedPhoneItem(isNDS ? matchExistingWithFuture : homePhoneInFuture);
        submitOrderArr.push(isNDS ? matchExistingWithFuture : homePhoneInFuture);
      } else if (homePhoneInFuture && !isHomeExists) {
        //user have to add home phone
        setIsAlreadyPhoneUnlimited("");
      } else if (isHomeExists && !homePhoneInFuture) {
        //show message that it cannot be supported
        setIsAlreadyPhoneUnlimited("notSupported");
      }
    }
  }, [futureAddOnPlanData?.newAddons]);
  useEffect(() => {
    setListingInfo({
      firstName: "",
      lastName: "",
      address: "",
      voicemail: "",
      city: "",
      state: "",
      zipcode: "",
    });
    setNewPhoneErrors({
      firstName: "",
      lastName: "",
      address: "",
      voicemail: "",
      city: "",
      state: "",
      zipcode: "",
    });
  }, [isSelected]);

  useEffect(() => {
    setPortErrors({
      portInNumber: "",
      accountNumber: "",
      existingPin: "",
      existingTelecomAddress: "",
    });
    setPortInfo({
      portInNumber: "",
      accountNumber: "",
      existingPin: "",
      existingTelecomAddress: "",
    });
  }, [isSelected]);

  useEffect(() => {
    if (accountUuid && email && data) {
      getPrimaryEmail();
    }
  }, [accountUuid, email, getPrimaryEmail, data]);

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      handleGetPlanDetails();
    }
  }, [accountNumber, data]);

  useEffect(() => {
    setSeId(selectedPlanDetails?.se);
  }, [selectedPlanDetails?.se]);

  useEffect(() => {
    dispatch(setNutritionLabelbyPlanError(false));
    dispatch(setAvailablePlansError(false));
    dispatch(setCustomerPlanDetailsError(false));
    setSelectedFiber({});
    dispatch(setIsSelected(false));
    setSelectPlan(null);
    setSubmitOrderArr([]);
    dispatch(setShowNextinAutoPay(false));
    dispatch(setAutopaySuccess(false));
    setCheckPaperless(false);
    setSubmitOrderErr({});
    setSubmitOrderFail(false);
    dispatch(setSlotSelected(false));
    setSubmitOrderResponse({});
    setOrderNumber("");
    getCustomerName();
  }, []);

  return (
    <Box minHeight={"100vh"}>
      <PageGrid container>
        <Grid item xs={12} lg={8.2}>
          <StyledLink to={"/account"}>
            <Stack direction={"row"} alignItems="center">
              {activeStep === 1 && <ArrowBackIosIcon color="secondary" />}
              <CardHeading>Upgrade my speed</CardHeading>
            </Stack>
          </StyledLink>
          <StyledCard>
            {planDetailsLoading || primaryEmailLoading ? (
              <Box my={24}>
                <Spinner />
              </Box>
            ) : customerPlandetailsError ? (
              <Box my={20}>
                <ErrorComponent onRefresh={getCustomerPlanDetails} />
              </Box>
            ) : successScreen ? (
              <Stack justifyContent={"center"} alignItems={"center"} spacing={2} my={4}>
                <img src={greenTickIcon} alt="tick" />
                <Typography
                  textAlign={"center"}
                  fontWeight={600}
                  fontSize={{ xs: "18px", md: "20px" }}>
                  Congratulations on your speed upgrade!
                </Typography>
                <Typography textAlign={"center"}>Order number : {orderID}</Typography>
                <Stack gap={2} width={"50%"}>
                  <Typography fontWeight={600}>What to Expect Next</Typography>
                  <Typography>
                    Keep an eye out for an email with details and instructions.
                  </Typography>
                  {submitOrderResponse?.appointments?.length > 0 || install ? (
                    <Typography>
                      We will be in touch about your appointment. You can view details or reschedule
                      on the Accounts tab.
                    </Typography>
                  ) : (
                    <ul>
                      <li>Your service will be upgraded within a few hours.</li>
                      <li>Your account will reflect this new plan within 24 hours.</li>
                      <li>Your next monthly bill will be pro-rated accordingly.</li>
                    </ul>
                  )}
                </Stack>
                <Button type="root" title="DONE" onClick={handleAutopayAndPaperlessErrors} />
              </Stack>
            ) : showErrorScreen ? (
              <AutoPayAndPaperlessError Messages={showErrorMessage} />
            ) : (
              <>
                <StepperWithoutLabels activeStep={activeStep} heading={stepperHeading} />
                <SelectNewSpeed
                  activeStep={activeStep}
                  existingPlanDetails={existingPlanDetails}
                  availablePlansloading={availablePlansloading}
                  availablePlansError={availablePlansError}
                  showMore={showMore}
                  handleAvailablePlans={handleAvailablePlans}
                  availablePlansForUpgrade={availablePlansData}
                  handleSeeMore={handleSeeMore}
                  setShowBroadbandFacts={setShowBroadbandFacts}
                  highestPlan={highestPlan}
                  setSelectPlan={setSelectPlan}
                  selectPlan={selectPlan}
                  futureRouterArray={futureRouterArray}
                  submitOrderArr={submitOrderArr}
                  setSubmitOrderArr={setSubmitOrderArr}
                  setInstallation={setInstallation}
                />
                {activeStep === 2 ? (
                  <>
                    <SelectEquipment
                      existingPlanDetails={existingPlanDetails}
                      addOnsLoading={addOnsLoading}
                      futureAddOnPlanErr={futureAddOnPlanErr}
                      handleGetFutureAddOns={handleGetFutureAddOns}
                      setSelectedPhoneItem={setSelectedPhoneItem}
                      selectedPhoneItem={selectedPhoneItem}
                      showRouterType={showRouterType}
                      setSelectedFiber={setSelectedFiber}
                      selectedFiber={selectedFiber}
                      isAlreadyPhoneUnlimited={isAlreadyPhoneUnlimited}
                      futureAddOnPlanData={futureAddOnPlanData}
                      addonContent={addonContent}
                      addOnContentErr={addOnContentErr}
                      addOnContentLoading={addOnContentLoading}
                      handleContentBySkus={handleContentBySkus}
                      setPortInfo={setPortInfo}
                      portInfo={portInfo}
                      setListingInfo={setListingInfo}
                      listingInfo={listingInfo}
                      setFutureRouterArray={setFutureRouterArray}
                      futureRouterArray={futureRouterArray}
                      setSubmitOrderPayload={setSubmitOrderPayload}
                      submitOrderPayload={submitOrderPayload}
                      selectPhoneVal={selectPhoneVal}
                      setSelectPhoneVal={setSelectPhoneVal}
                      homePhoneVal={homePhoneVal}
                      setHomePhoneVal={setHomePhoneVal}
                      NewPhoneErrors={NewPhoneErrors}
                      portErros={portErros}
                      setPortErrors={setPortErrors}
                      setNewPhoneErrors={setNewPhoneErrors}
                      addPhone={addPhone}
                      setAddPhone={setAddPhone}
                      submitOrderArr={submitOrderArr}
                      setSubmitOrderArr={setSubmitOrderArr}
                      setInstallation={setInstallation}
                      isNDS={isNDS}
                      copperToFiber={copperToFiber}
                      setShowRouterType={setShowRouterType}
                      setUpdateRouterType={setUpdateRouterType}
                      isByoRouter={isByoRouter}
                      setIsAlreadyPhoneUnlimited={setIsAlreadyPhoneUnlimited}
                      isHomeInExistingAddon={isHomeInExistingAddon}
                      isHavingMatchingRouter={isHavingMatchingRouter}
                      updateRouterType={updateRouterType}
                      selectedPlanDetails={selectedPlanDetails}
                    />
                  </>
                ) : activeStep === 3 ? (
                  <VerifyInfo
                    email={primaryEmail ?? email}
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                    checkAutopay={checkAutopay}
                    checkPaperless={checkPaperless}
                    setCheckAutopay={setCheckAutopay}
                    setCheckPaperless={setCheckPaperless}
                    handlePhoneNumberBlur={handlePhoneNumberBlur}
                    verifyInfoErrors={verifyInfoErrors}
                    setVerifyInfoErrors={setVerifyInfoErrors}
                    openAutopay={openAutopay}
                    existingAddons={existingPlanDetails?.addons}
                    submitOrderArr={submitOrderArr}
                    setSubmitOrderArr={setSubmitOrderArr}
                    handleSubmitOrder={handleSubmitOrder}
                    setFutureRouterArray={setFutureRouterArray}
                    futureRouterArray={futureRouterArray}
                  />
                ) : (
                  activeStep === 4 &&
                  falloutLoading === false && (
                    <SlotSelection
                      submitOrderResponse={submitOrderResponse}
                      selectedSlotObj={selectedSlotObj}
                      setSelectedSlotObj={setSelectedSlotObj}
                    />
                  )
                )}
                {!getAddOndsForFuturePlanError && (
                  <>
                    {activeStep !== 1 && !openAutopay && (
                      <MonthlyBillCart
                        submitOrderPayload={submitOrderPayload}
                        orderTotalPrice={orderTotalPrice}
                        setOrderTotalPrice={setOrderTotalPrice}
                        submitOrderArr={submitOrderArr}
                        installation={installation}
                        promotion={promotion}
                        selectedFiber={selectedFiber}
                        billingAddress={billingAddress}
                        serviceAddress={serviceAddress}
                        addPhone={addPhone}
                      />
                    )}
                  </>
                )}
                {!addOnsLoading && (
                  <Stack
                    my={2}
                    direction={"row"}
                    width={"100%"}
                    justifyContent={activeStep === 1 ? "end" : "space-between"}>
                    {activeStep !== 1 && (
                      <Button type="pay" title="BACK" onClick={handlePreviousStep} />
                    )}
                    {!showNextinAutoPay && !AddonsForFuturePlanError && !futureAddOnPlanErr && (
                      <Button
                        type="payment"
                        title={
                          (install === true && activeStep === 4) ||
                          (selectedFiber?.name?.toLowerCase() === "no router" &&
                            selectedPlanDetails?.productId !== "FIBER_10G" &&
                            activeStep === 3)
                            ? "CONFIRM ORDER"
                            : "NEXT"
                        }
                        disabled={!isSelected || (!selectedSlot && activeStep == 4) || loading}
                        onClick={handleNextStep}
                      />
                    )}
                  </Stack>
                )}
                {/* Facts to be displayed upon selecting a plan */}
                {nutritionByPlanLoading ? (
                  <Box my={6}>
                    <Spinner />
                  </Box>
                ) : nutritionLabelbyPlanError ? (
                  <Box my={4}>
                    <ErrorComponent onRefresh={() => getNutritionLabelByPlan()} />
                  </Box>
                ) : (
                  activeStep === 1 &&
                  showBroadbandFacts &&
                  !ZoomOutFlag && (
                    <Stack alignItems={"center"}>
                      <BroadbandFactsCard handleNutritionLabel={() => getNutritionLabelByPlan()} />
                    </Stack>
                  )
                )}
                {nutritionByPlanData?.nutritionLabelByPlanDetails && ZoomOutFlag && (
                  <NutritionLabel
                    fromPlanDetails={true}
                    dataFromPlan={nutritionByPlanData?.nutritionLabelByPlanDetails}
                    accountNumberFromPlan={accountNumber}
                  />
                )}

                {addressFromBillingDetails && (
                  <Typography
                    sx={{ display: activeStep === 1 ? "block" : "none" }}
                    textAlign={"center"}>
                    For : {addSpaceAfterComma(serviceAddress)}
                  </Typography>
                )}
              </>
            )}
          </StyledCard>
        </Grid>

        <Grid
          item
          xs={12}
          lg={3.5}
          display={{ xs: "block", lg: scrollPosition > 15 ? "block" : "none" }}>
          <AccountCard />
          <NeedHelpCard />
        </Grid>
      </PageGrid>
      <StickyBox display={{ xs: "none", lg: scrollPosition > 15 ? "none" : "block" }}>
        <AccountCard />
        <NeedHelpCard />
      </StickyBox>
      {(setPaperLessLoading ||
        submitOrderLoading ||
        submitOrderLegacyLoading ||
        falloutLoading ||
        commitOrderLoading) && <LoadingSpinner />}
      {falloutErr && (
        <ErrorPopup
          openError={falloutErr}
          setOpenError={setFalloutErr}
          message="We're unable to retrieve this information at the moment. Please try again later."
        />
      )}

      {submitOrderFail && (
        <ErrorPopup
          openError={submitOrderFail}
          setOpenError={setSubmitOrderFail}
          message="We're unable to retrieve this information at the moment. Please try again later."
        />
      )}

      {submitOrderLegacyErr && (
        <ErrorPopup openError={submitOrderLegacyErr} setOpenError={setSubmitOrderLegacyErr} />
      )}
      {successMessage && (
        <>
          <SuccessMessageModel
            successMessage={successMessage}
            setSuccessMessage={setSuccessMessage}
            userStatus={userStatus}
            note={"You will save $5/mo."}
          />
        </>
      )}
    </Box>
  );
};
export default OrderUpgrades;

import React, { useState, useEffect } from "react";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import { useSelector, useDispatch } from "react-redux";
import TimelineContent from "@mui/lab/TimelineContent";
import { Stack, Typography, Box } from "@mui/material";
import Button from "../../../../components/Button/Button";
import { Theme } from "../../../../Styles/GlobalTheme";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandableSectionContent from "./ExpandableSectionContent";
import {
  CONNECTION_QUALITY_TEST,
  CONNECTION_QUALITY_TEST_INFO,
  CONNECTION_QUALITY_TEST_MESSAGE,
  HELP_CENTER_LINK,
  INTERNET_SPEED_TEST,
  PANEL_1,
  ROUTER_CONECTION_NOTE,
  ROUTER_CONNECTION_INACTIVE_TEXT,
  ROUTER_CONNECTION_TEST,
} from "../../../../Constants/constants";
import LinearDeterminateProgress from "../../../../components/shared/LinearDeterminateProgress/LinearDeterminateProgress";
import {
  setRebootRGMessage,
  setRgStatusRebootSuccess,
  setSpeedTestApiError,
} from "../../../../Redux/Slices/NetworkStatusSlice";
import {
  ColumnCenterStack,
  InfoTooltip,
  ItalicTypography,
  StyleAccordian,
  StyleAccordionSummary,
  StyledElement,
} from "../../../../Styles/GlobalStyles";
import {
  setLoadingDownload,
  setLoadingUpload,
  setSpeedData,
  setSpeedResult,
  setSpeedTestAgain,
  setSpeedTestComplete,
} from "../../../../Redux/Slices/SpeedTestAnimationSlice";
import RouterIcon from "../../../../assets/icons/Router Web.svg";
import GlobeIcon from "../../../../assets/icons/Globe Web.svg";
import InfoIcon from "../../../../assets/icons/Information Web.svg";
import { SPEED_TEST_INFO_MESSAGE } from "../../../../Constants/constants";
import SuccessDialogBox from "./SuccessDialogBox";
import LatestSpeedTest from "../SpeedtTest/LatestSpeedTest";
import { NetworkStatusErrorComponent } from "./NetworkStatusErrorComponent";
import { useTestConnection } from "../../../../hooks/networkStatus/useTestConnection";
import { useRGStatus } from "../../../../hooks/networkStatus/useRGStatus";
import ConnectionTestIcon from "../../../../assets/icons/ConnectionQualityTestLoadUpdated.svg";
import ContactSupport from "../../../../components/shared/ContactSupport/ContactSupport";

const ExpandableSectionHeader = ({
  icon,
  primaryBoldText,
  italicText,
  primaryBtn,
  primaryType,
  secondaryBtn,
  secondaryType,
  data,
  error,
  rebootLoading,
  onClickSecondary,
  errorMessage,
  message,
  submessage,
  accountNum,
  supportingText,
}: any) => {
  const REBOOTING_ROUTER = "Rebooting Router...";
  const [expanded, setExpanded] = useState<string | false>(false);
  const [loadingText, setLoadingText] = useState<string>("");
  const { accountNumber } = useSelector((state: any) => state.customerInfoDetails);
  const dispatch = useDispatch();
  const { getTestConnectionDetails, loading } = useTestConnection(accountNum);
  const { getRGStatus, rgStatusLoading } = useRGStatus(accountNum);
  const isLoading = loading || rgStatusLoading || rebootLoading;
  const {
    speedTestApiError,
    isZiplyRouter,
    rgStatusRebootSuccess,
    testConnectionApiErrorMsg,
    networkConnectionDetails,
  } = useSelector((state: any) => state.networkStatusDetails);
  const {
    loadingUpload,
    loadingDownload,
    speedtestAgain,
    speedData,
    speedResult,
    speedTestComplete,
  } = useSelector((state: any) => state.speedTestAnimation);

  const handleChange =
    (panel: string, primaryBoldText: any) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
      dispatch(setRebootRGMessage(""));
      if (primaryBoldText === INTERNET_SPEED_TEST) {
        speedTestApiError && dispatch(setSpeedTestApiError(false));
        speedtestAgain && dispatch(setSpeedTestAgain(false));
        loadingUpload && dispatch(setLoadingUpload(false));
        loadingDownload && dispatch(setLoadingDownload(false));
        speedData && dispatch(setSpeedData({}));
        speedResult && dispatch(setSpeedResult({}));
        speedTestComplete && dispatch(setSpeedTestComplete(false));
      }
    };

  const handleLoadingText = () => {
    switch (primaryBoldText) {
      case INTERNET_SPEED_TEST:
        return setLoadingText("");
      case CONNECTION_QUALITY_TEST:
        return setLoadingText("Running connection tests...");
      case ROUTER_CONNECTION_TEST:
        return setLoadingText("Running router tests...");
      default:
        return setLoadingText("Running tests...");
    }
  };

  function handleSecondaryClick() {
    setLoadingText(REBOOTING_ROUTER);
    onClickSecondary();
  }
  function handlePrimaryClick() {
    handleLoadingText();
    switch (primaryBoldText) {
      case CONNECTION_QUALITY_TEST:
        getTestConnectionDetails();
        break;
      case ROUTER_CONNECTION_TEST:
        getRGStatus();
        break;
      default:
        break;
    }
  }

  const onCloseDialog = () => {
    dispatch(setRgStatusRebootSuccess(false));
  };

  useEffect(() => {
    handleLoadingText();
  }, [primaryBoldText]);

  return (
    <>
      <StyleAccordian
        disableGutters
        elevation={0}
        expanded={expanded === PANEL_1}
        onChange={handleChange(PANEL_1, primaryBoldText)}>
        <StyleAccordionSummary
          sx={{ p: 0 }}
          expandIcon={
            <StyledElement>
              <ExpandMoreIcon />
            </StyledElement>
          }>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot
                style={{ height: "54px", width: "54px" }}
                color="secondary"
                sx={{ p: 1, m: 0 }}>
                <img src={icon} style={{ width: "100%", height: "100%" }} alt="icon" />
              </TimelineDot>
              <TimelineConnector
                sx={{
                  backgroundColor: Theme.palette.secondary.main,
                  display:
                    ((isZiplyRouter && primaryBoldText === ROUTER_CONNECTION_TEST) ||
                      (!isZiplyRouter && primaryBoldText === CONNECTION_QUALITY_TEST)) &&
                    expanded !== PANEL_1
                      ? "none"
                      : "block",
                }}
              />
            </TimelineSeparator>
            <TimelineContent sx={{ p: 1, pt: 0 }}>
              <Stack direction={"row"} spacing={1}>
                <Typography
                  sx={{ marginTop: "12px" }}
                  fontWeight={600}
                  fontSize={{ xs: "15px", md: "16px" }}
                  component="span">
                  {primaryBoldText}
                </Typography>
                {primaryBoldText === CONNECTION_QUALITY_TEST && (
                  <InfoTooltip title={<span>{CONNECTION_QUALITY_TEST_INFO}</span>}>
                    <img src={InfoIcon} alt="information icon" />
                  </InfoTooltip>
                )}
              </Stack>
              <Stack direction={"row"} spacing={1}>
                <ItalicTypography>{italicText}</ItalicTypography>
                {primaryBoldText === INTERNET_SPEED_TEST && (
                  <InfoTooltip title={<span>{SPEED_TEST_INFO_MESSAGE}</span>}>
                    <img src={InfoIcon} alt="information icon" />
                  </InfoTooltip>
                )}
              </Stack>
            </TimelineContent>
          </TimelineItem>
        </StyleAccordionSummary>
        <TimelineItem sx={{ pl: "26px" }}>
          <TimelineSeparator>
            <TimelineConnector sx={{ backgroundColor: Theme.palette.secondary.main }} />
          </TimelineSeparator>
          <TimelineContent
            sx={{ py: 0, backgroundColor: Theme.palette.customcolor.accordionbackground }}>
            {primaryBoldText === INTERNET_SPEED_TEST ? (
              <LatestSpeedTest />
            ) : (
              <>
                {error && !isLoading ? (
                  <NetworkStatusErrorComponent
                    errorMessage={errorMessage}
                    handleRefresh={handlePrimaryClick}
                    buttonTitle={"RE-RUN TEST"}
                  />
                ) : (
                  <>
                    {!isLoading && (
                      <ExpandableSectionContent
                        primaryBoldText={primaryBoldText}
                        data={data}
                        loading={isLoading}
                        message={message}
                        submessage={submessage}
                        supportingText={supportingText}
                      />
                    )}
                    {networkConnectionDetails === null &&
                      !isLoading &&
                      primaryBoldText === CONNECTION_QUALITY_TEST && (
                        <>
                          <NetworkStatusErrorComponent
                            errorMessage={testConnectionApiErrorMsg}
                            handleRefresh={handlePrimaryClick}
                          />
                        </>
                      )}
                    {isLoading ? (
                      rebootLoading ? (
                        <Box my={7}>
                          <Stack direction={"column"} justifyContent="center" alignItems={"center"}>
                            <img src={RouterIcon} alt="router icon" />
                            <Typography
                              p={2}
                              textAlign="center"
                              fontSize="12px"
                              color={Theme.palette.customcolor.customText}>
                              {loadingText}
                            </Typography>
                            <LinearDeterminateProgress />
                          </Stack>
                        </Box>
                      ) : (
                        <Box my={7}>
                          <Typography
                            textAlign="center"
                            fontSize="12px"
                            color={Theme.palette.customcolor.customText}>
                            {loadingText}
                          </Typography>
                          <Stack direction="row" justifyContent="center">
                            <Box p={2} m={0.4}>
                              <img
                                src={
                                  primaryBoldText === CONNECTION_QUALITY_TEST
                                    ? ConnectionTestIcon
                                    : RouterIcon
                                }
                                alt="router icon"
                              />
                            </Box>
                            <LinearDeterminateProgress />
                            <Box p={2} my={1}>
                              <img src={GlobeIcon} alt="globe icon" />
                            </Box>
                          </Stack>
                        </Box>
                      )
                    ) : (
                      <>
                        <Stack
                          direction={{ xs: "column", sm: "row" }}
                          spacing={2}
                          justifyContent={"center"}
                          alignItems={"center"}
                          my={2}
                          pb={6}>
                          {CONNECTION_QUALITY_TEST &&
                            message === CONNECTION_QUALITY_TEST_MESSAGE && (
                              <Button
                                title={"HELP CENTER"}
                                onClick={() => window.open(HELP_CENTER_LINK, "_blank")}
                                type={primaryType}
                              />
                            )}
                          {primaryBtn && (
                            <Button
                              title={primaryBtn}
                              onClick={handlePrimaryClick}
                              type={primaryType}
                            />
                          )}
                          {secondaryBtn && (
                            <Button
                              title={secondaryBtn}
                              onClick={handleSecondaryClick}
                              type={secondaryType}
                            />
                          )}
                        </Stack>
                        {message === ROUTER_CONNECTION_INACTIVE_TEXT && (
                          <Stack flexWrap={"wrap"} width="100%" my={2}>
                            <Typography
                              fontSize={{ xs: "12px", sm: "13px" }}
                              color={Theme.palette.customcolor.customText}>
                              {ROUTER_CONECTION_NOTE}
                            </Typography>
                            <Stack direction={"row"} spacing={0.5} my={1} alignItems={"center"}>
                              <Typography
                                fontSize={{ xs: "14px", md: "14px" }}
                                color={Theme.palette.customcolor.customText}>
                                Please contact{" "}
                              </Typography>
                              <ContactSupport text={"OUR TEAM"} />
                              <Typography
                                fontSize={{ xs: "14px", md: "14px" }}
                                color={Theme.palette.customcolor.customText}>
                                to upgrade to a Ziply Fiber WiFi router.
                              </Typography>
                            </Stack>
                          </Stack>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </TimelineContent>
        </TimelineItem>
      </StyleAccordian>
      <SuccessDialogBox
        open={rgStatusRebootSuccess}
        onClose={onCloseDialog}
        heading={"Router Reset Successfully"}
        message={
          <ColumnCenterStack gap={1} my={1}>
            <Typography>Your Router has been rebooted successfully</Typography>
          </ColumnCenterStack>
        }
      />
    </>
  );
};

export default ExpandableSectionHeader;

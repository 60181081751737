import { Box, Stack, styled, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BouncingDotsLoader } from "./BouncingDots/BouncingDotsLoader";
import { Theme } from "../../../../Styles/GlobalTheme";
import speedDownloadIcon from "../../../../assets/icons/SpeedtestDownloadIcon.svg";
import speedUploadIcon from "../../../../assets/icons/SpeedtestUploadIcon.svg";
import { useSelector } from "react-redux";
interface SpeedoMeterProps {
  speed: number;
  strokColor: string;
  text: string;
}

const SpeedoMeter: React.FC<SpeedoMeterProps> = ({ speed, strokColor, text }) => {
  const isMobile = useMediaQuery(() => Theme.breakpoints.down("sm"));
  const size = isMobile ? 190 : 200;
  const padding = 5;
  const barWidth = 8;
  const backgroundWidth = 12;
  const arcSweepAngle = 270;
  const backgroundColor = "#d6d6d6";
  const rotation = 224;
  const [currentFill, setCurrentFill] = useState(0);
  const [count, setCount] = React.useState(0);
  const speedMarkers = [0, 20, 50, 100, 500, 1000, 1500];
  const markerAngles = [0, 45, 90, 135, 180, 225, 270];

  const { loadingDownload, loadingUpload, speedTestComplete } = useSelector(
    (state: any) => state.speedTestAnimation
  );
  const polarToCartesian = (
    centerX: number,
    centerY: number,
    radius: number,
    angleInDegrees: number
  ) => {
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians),
    };
  };

  const circlePath = (
    x: number,
    y: number,
    radius: number,
    startAngle: number,
    endAngle: number
  ) => {
    const start = polarToCartesian(x, y, radius, endAngle * 0.9999);
    const end = polarToCartesian(x, y, radius, startAngle);
    const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";
    const d = ["M", start.x, start.y, "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y];
    return d.join(" ");
  };

  const calculateAngleForSpeed = (speedValue: number) => {
    if (speedValue <= speedMarkers[0]) return markerAngles[0];
    if (speedValue >= speedMarkers[speedMarkers.length - 1])
      return markerAngles[markerAngles.length - 1];
    for (let i = 0; i < speedMarkers.length - 1; i++) {
      if (speedValue >= speedMarkers[i] && speedValue <= speedMarkers[i + 1]) {
        const speedDiff = speedMarkers[i + 1] - speedMarkers[i];
        const angleDiff = markerAngles[i + 1] - markerAngles[i];
        const ratio = (speedValue - speedMarkers[i]) / speedDiff;
        return markerAngles[i] + ratio * angleDiff;
      }
    }

    return 0;
  };

  const sizeWithPadding = size / 2 + padding / 2;
  const maxWidthCircle = Math.max(barWidth, backgroundWidth);
  const radius = size / 2 - maxWidthCircle / 2 - padding / 2;
  const currentAngle = calculateAngleForSpeed(currentFill);

  const backgroundPath = circlePath(sizeWithPadding, sizeWithPadding, radius, 0, arcSweepAngle);
  const progressPath = circlePath(sizeWithPadding, sizeWithPadding, radius, 0, currentAngle);

  const calculateDotPosition = (radius: number, angle: number) => {
    const centerX = sizeWithPadding;
    const centerY = sizeWithPadding;
    const angleInRadians = ((angle - 90) * Math.PI) / 180;
    const x = centerX + radius * Math.cos(angleInRadians);
    const y = centerY + radius * Math.sin(angleInRadians);
    return { x, y };
  };

  const dotPosition = calculateDotPosition(radius, currentAngle);

  useEffect(() => {
    const desiredSpeed = speed;
    const animationDuration = 5000;
    const animationInterval = 10;
    const increment = (desiredSpeed * animationInterval) / animationDuration;
    let currentSpeed = 0;

    const intervalId = setInterval(() => {
      currentSpeed += increment;
      setCurrentFill(currentSpeed);
      setCount(Math.floor(currentSpeed));

      if (currentSpeed >= desiredSpeed) {
        clearInterval(intervalId);
        setCount(desiredSpeed);
        setCurrentFill(desiredSpeed);
      }
    }, animationInterval);

    return () => clearInterval(intervalId);
  }, [speed]);

  const renderNumbers = () => {
    const displayAngles = [230, 270, 310, 3, 50, 90, 130];

    return speedMarkers.map((number, index) => {
      const angle = displayAngles[index];
      const position = calculateDotPosition(radius + 30, angle);

      return (
        <AbsolutePositionedText
          key={number}
          variant="body2"
          style={{
            position: "absolute",
            left: position.x,
            top: position.y,
            transform: "translate(-50%, -50%)",
          }}>
          {number === 1500 ? `${number}+` : number}
        </AbsolutePositionedText>
      );
    });
  };

  return (
    <Box sx={speedometerStyles.speedometerContainer}>
      <Box sx={speedometerStyles.speedometer}>
        {renderNumbers()}
        <svg width={size + padding} height={size + padding}>
          <g transform={`rotate(${rotation}, ${(size + padding) / 2}, ${(size + padding) / 2})`}>
            <path
              d={backgroundPath}
              stroke={backgroundColor}
              strokeWidth={18}
              strokeLinecap="round"
              strokeDasharray="3"
              fill="transparent"
            />

            {currentFill > 0 && (
              <path
                d={progressPath}
                stroke={strokColor}
                strokeWidth={18}
                strokeLinecap="round"
                fill="transparent"
              />
            )}

            {currentFill > 0 && <circle cx={dotPosition.x} cy={dotPosition.y} r={9} fill="white" />}
          </g>
        </svg>

        <Box
          sx={{
            position: "absolute",
            top: "55%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
          }}>
          {!speedTestComplete && !(loadingDownload || loadingUpload) && (
            <>
              {text === "Speed Test" ? (
                <Typography
                  fontSize={16}
                  color={Theme.palette.secondary.main}
                  sx={{ display: "inline-flex", whiteSpace: "nowrap" }}>
                  {text}
                </Typography>
              ) : (
                <>
                  <Typography fontSize={24}>{count}</Typography>
                  <Typography
                    fontSize={14}
                    color={"#666"}
                    sx={{ display: "inline-flex", whiteSpace: "nowrap" }}>
                    {text}
                  </Typography>
                </>
              )}
            </>
          )}
        </Box>

        <Box
          sx={{
            position: "absolute",
            bottom: "10px",
            left: "50%",
            transform: "translateX(-50%)",
            "@media (min-width: 600px) and (max-width: 960px)": {
              left: "45%",
              transform: "none",
            },
          }}>
          <BouncingDotsLoader />
        </Box>
        <Stack
          my={2}
          sx={{
            position: "absolute",
            top: "90%",
            left: "50%",
            transform: "translateX(-50%)",
            width: { sm: "45%", md: "80%" },
            spacing: 1,
            justifyContent: "center",
            alignItems: "center",
          }}>
          {loadingDownload ? (
            <img src={speedDownloadIcon} alt="/" />
          ) : (
            loadingUpload && <img src={speedUploadIcon} alt="/" />
          )}

          <Typography
            sx={{ display: "inline-flex", whiteSpace: "nowrap" }}
            color={
              loadingDownload
                ? Theme.palette.primary.main
                : loadingUpload
                ? Theme.palette.secondary.main
                : ""
            }>
            {loadingDownload
              ? "Testing Download Speed"
              : loadingUpload
              ? "Testing Upload Speed"
              : ""}
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
};

const speedometerStyles = {
  speedometerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  speedometer: {
    position: "relative",
    width: 205,
    height: 205,
  },
  speedValue: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: "#0047BB",
  },
};

const AbsolutePositionedText = styled(Typography)({
  position: "absolute",
  fontSize: "0.9rem",
  color: "#666",
});

export default SpeedoMeter;

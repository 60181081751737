import { useEffect, useState } from "react";
import {
  CardHeading,
  PageGrid,
  StickyBox,
  StyledCard,
  StyledLink,
} from "../../../../Styles/GlobalStyles";
import { Grid, Stack, Typography, Box, Divider } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { RunSpeedTestComponent } from "./RunSpeedTestComponent";
import { Theme } from "../../../../Styles/GlobalTheme";
import Button from "../../../../components/Button/Button";
import { NeedHelpCard } from "../../../../components/shared/NeedHelpCard/NeedHelpCard";
import { AccountCard } from "../../../../components/shared/AccountCard/AccountCard";
import { useSelector, useDispatch } from "react-redux";
import arrowIcon from "../../../../assets/icons/arrow.svg";
import "./RunSpeedTest.css";
import {
  setFadeOut,
  setLoadingDownload,
  setLoadingUpload,
  setSpeedTestAgain,
  setStartDownloadSpeed,
  setStartLoader,
  setStartUploadSpeed,
} from "../../../../Redux/Slices/SpeedTestAnimationSlice";
import { useLazyQuery } from "@apollo/client";
import { GET_SPEED_TEST_DETAILS } from "../../query";
import backgroundImg from "../../../../assets/images/Clouds.svg";
import warningIcon from "../../../../assets/icons/WarningGrey.svg";

export const SpeedTest = () => {
  const isSpeedTestComponent = window.location.pathname === "/myaccount/home/speedtest";
  const { accountNumber, ndsObj } = useSelector((state: any) => state?.customerInfoDetails);

  const [getSpeedTestDetails, { loading: speedestLoading, data: speedTestDetails }] = useLazyQuery(
    GET_SPEED_TEST_DETAILS,
    {
      variables: {
        accountNumber: accountNumber,
        isNds: ndsObj?.isNDS,
      },
      fetchPolicy: "network-only",
      onCompleted: () => handleSuccessRunSpeed(),
      onError: () => handleFailureRunSpeed(),
    }
  );

  const { scrollPosition } = useSelector((state: any) => state.pageInfo);
  const dispatch = useDispatch();
  const {
    startDownloadSpeed,
    speedtestAgain,
    fadeOut,
    startUploadSpeed,
    startLoader,
    loadingUpload,
  } = useSelector((state: any) => state.speedTestAnimation);

  speedestLoading && dispatch(setStartDownloadSpeed(true)) && dispatch(setLoadingDownload(true));

  const handleRunSpeedTest = () => {
    accountNumber?.trim()?.length > 0 && getSpeedTestDetails();
  };

  const handleSpeedTest = () => {
    if (isSpeedTestComponent && speedtestAgain) {
      window.location.reload();
    } else if (!isSpeedTestComponent && speedtestAgain) {
      dispatch(setStartUploadSpeed(false));
      dispatch(setLoadingUpload(false));
      dispatch(setFadeOut(false));
      dispatch(setSpeedTestAgain(false));
    } else {
      handleRunSpeedTest();
    }
  };

  const handleSuccessRunSpeed = () => {
    dispatch(setLoadingUpload(true));
    dispatch(setStartUploadSpeed(true));
    dispatch(setLoadingDownload(false));
    dispatch(setStartDownloadSpeed(false));

    setTimeout(() => {
      dispatch(setStartUploadSpeed(false));
      dispatch(setLoadingUpload(false));
      dispatch(setFadeOut(true));
      dispatch(setSpeedTestAgain(true));
    }, 8000);
  };

  const handleFailureRunSpeed = () => {
    dispatch(setStartLoader(false));
    dispatch(setLoadingDownload(false));
  };

  const testDetails = speedTestDetails?.speedTestDetails?.testDetails;
  const showSpeedTestError = testDetails === null;

  useEffect(() => {
    dispatch(setFadeOut(false));
    dispatch(setSpeedTestAgain(false));
  }, []);

  return (
    <PageGrid container p={!isSpeedTestComponent ? "0 !important" : ""}>
      <Grid item lg={!isSpeedTestComponent ? 12 : 8.3} xs={12}>
        {isSpeedTestComponent && (
          <StyledLink to={"/home"}>
            <Stack direction={"row"} alignItems="center">
              <ArrowBackIosIcon color="secondary" />
              <CardHeading>Speed Test</CardHeading>
            </Stack>
          </StyledLink>
        )}
        <StyledCard
          sx={{
            overflow: "hidden",
            boxShadow: !isSpeedTestComponent ? "none" : "",
            background: !isSpeedTestComponent ? Theme.palette.customcolor.accordionbackground : "",
            margin: !isSpeedTestComponent ? "0" : "",
            padding: !isSpeedTestComponent ? "0" : "",
          }}>
          {testDetails !== null && (
            <>
              {!isSpeedTestComponent ? (
                <Typography fontSize={"12px"} color={Theme.palette.customcolor.customText}>
                  Note: The speed test results could potentially be less than the true network
                  performance offered by Ziply Fiber because of constraints imposed by the test
                  server and fluctuations in network conditions at the time of the test.
                </Typography>
              ) : (
                <Typography>
                  Check your Internet speed under 30 seconds. Your speed test will be done from your
                  Router. The speed test usually transfers less than <b> 40 MB of data</b>, but may
                  transfer more data on fast connections.
                </Typography>
              )}
              <Box mt={isSpeedTestComponent ? 10 : 6}>
                <Box>
                  <RunSpeedTestComponent speedestLoading={speedestLoading} />
                </Box>
                {(startUploadSpeed || startDownloadSpeed) && (
                  <>
                    <Stack
                      width={"100%"}
                      mb={2}
                      gap={2}
                      justifyContent={"center"}
                      alignItems={"center"}>
                      <Box
                        sx={{
                          transform: (loadingUpload || startUploadSpeed) && "rotate(180deg)",
                          transition: (loadingUpload || startUploadSpeed) && "transform 150ms ease",
                          transitionDuration: "3s",
                        }}>
                        <img
                          className={(loadingUpload || startUploadSpeed) && "logo-img"}
                          style={{
                            color:
                              loadingUpload || startUploadSpeed
                                ? Theme.palette.secondary.light
                                : Theme.palette.primary.light,
                          }}
                          height={20}
                          src={arrowIcon}
                          alt="arrow"
                        />
                      </Box>
                      <Typography
                        color={
                          loadingUpload || startUploadSpeed
                            ? Theme.palette.secondary.light
                            : Theme.palette.primary.light
                        }>
                        {loadingUpload || startUploadSpeed ? "Testing Upload" : "Testing Download"}
                      </Typography>
                    </Stack>
                  </>
                )}
                {(startUploadSpeed || startDownloadSpeed || fadeOut) && (
                  <Stack
                    mb={2}
                    className={"thumbnail-component"}
                    direction={"row"}
                    justifyContent={"center"}
                    spacing={4}
                    alignItems={"flex-end"}>
                    <Stack
                      className="text"
                      sx={{
                        transform: fadeOut
                          ? {
                              xs: "translateY(-800%)",
                              sm: "translateY(-300%)",
                            }
                          : "none",
                      }}>
                      {!startDownloadSpeed && !speedestLoading && (
                        <Typography
                          textAlign={"center"}
                          color={Theme.palette.primary.light}
                          fontWeight={600}>
                          {speedTestDetails?.speedTestDetails?.testDetails?.downloadSpeed}&nbsp;
                          {speedTestDetails?.speedTestDetails?.testDetails?.speedMeasurement}
                        </Typography>
                      )}
                      <Typography color={Theme.palette.customcolor.customText}>
                        Mbps Download
                      </Typography>
                    </Stack>
                    <Divider
                      orientation="vertical"
                      sx={{ height: 60, display: fadeOut ? "none" : "block" }}
                      flexItem
                    />
                    <Stack
                      className="text"
                      sx={{
                        transform: fadeOut
                          ? {
                              xs: "translateY(-800%)",
                              sm: "translateY(-300%)",
                            }
                          : "none",
                      }}>
                      {!startUploadSpeed &&
                        !speedestLoading &&
                        !startLoader &&
                        !startDownloadSpeed && (
                          <Typography
                            textAlign={"center"}
                            color={Theme.palette.secondary.light}
                            fontWeight={600}>
                            {speedTestDetails?.speedTestDetails?.testDetails?.uploadSpeed}&nbsp;
                            {speedTestDetails?.speedTestDetails?.testDetails?.speedMeasurement}
                          </Typography>
                        )}
                      <Typography color={Theme.palette.customcolor.customText}>
                        Mbps Upload
                      </Typography>
                    </Stack>
                  </Stack>
                )}

                {fadeOut && (
                  <Box>
                    <Stack justifyContent={"center"} alignItems={"center"}>
                      <Typography>
                        Latency : &nbsp;
                        {speedTestDetails?.speedTestDetails?.testDetails?.downstreamLatency}&nbsp;
                        {speedTestDetails?.speedTestDetails?.testDetails?.latencyMeasurement}
                      </Typography>
                      {/* no server field from API */}
                      {/* <Typography>Server : </Typography> */}
                    </Stack>

                    {/* <Typography component={"p"} textAlign={"center"}>
                      Want to get more Internet Speed?&nbsp;
                      <Typography
                        component={"span"}
                        fontWeight={600}
                        sx={{ textDecoration: "underline" }}
                        color={Theme.palette.secondary.main}>
                        UPGRADE PLAN HERE
                      </Typography>
                    </Typography> */}
                  </Box>
                )}
                <Box
                  textAlign={"center"}
                  my={4}
                  display={
                    !(startLoader || startUploadSpeed || startDownloadSpeed) ? "block" : "none"
                  }>
                  <Button
                    title={speedtestAgain ? "TEST AGAIN" : "RUN A SPEED TEST"}
                    type="root"
                    onClick={handleSpeedTest}
                  />
                </Box>
                {isSpeedTestComponent && (
                  <Typography fontSize={"12px"} color={Theme.palette.customcolor.customText} mt={4}>
                    Note: The speed test results could potentially be less than the true network
                    performance offered by Ziply Fiber because of constraints imposed by the test
                    server and fluctuations in network conditions at the time of the test.
                  </Typography>
                )}
              </Box>
            </>
          )}
          {showSpeedTestError && (
            <>
              <Box py={20}>
                <Box position={"relative"}>
                  <Box width={"100%"} position={"absolute"}>
                    <img
                      src={backgroundImg}
                      alt="background"
                      width={"100%"}
                      style={{ objectFit: "cover", height: "auto" }}
                    />
                  </Box>
                </Box>

                <Stack
                  bottom={40}
                  justifyContent={"center"}
                  alignItems={"center"}
                  position={"relative"}>
                  <img
                    src={warningIcon}
                    alt="warning"
                    width={"100%"}
                    style={{ objectFit: "cover", height: "130px" }}
                  />
                  <Typography fontWeight={600} fontSize={{ xs: "14px", md: "16px" }}>
                    Speed test currently unavailable
                  </Typography>
                  <Typography fontSize={{ xs: "14px", md: "16px" }}>
                    Please try again in sometime
                  </Typography>
                  <br />
                  <Typography fontSize={{ xs: "14px", md: "16px" }}>
                    If problem persists please contact us
                  </Typography>
                  <Typography fontSize={{ xs: "14px", md: "16px" }} component={"p"}>
                    <a
                      style={{
                        fontWeight: "bold",
                        textDecoration: "none",
                        color: Theme.palette.secondary.light,
                      }}
                      href="tel:+01128192891">
                      CONTACT HELP
                    </a>
                  </Typography>
                </Stack>
              </Box>
            </>
          )}
        </StyledCard>
      </Grid>
      {isSpeedTestComponent && (
        <>
          <Grid
            item
            xs={12}
            lg={3.5}
            display={{ xs: "block", lg: scrollPosition > 290 ? "block" : "none" }}>
            <AccountCard />
            <NeedHelpCard />
          </Grid>
          <StickyBox display={{ xs: "none", lg: scrollPosition > 290 ? "none" : "block" }}>
            <AccountCard />
            <NeedHelpCard />
          </StickyBox>
        </>
      )}
    </PageGrid>
  );
};

import React from "react";
import { Stack, Typography, styled } from "@mui/material";
import ErrorLoadingIcon from "../../../../assets/icons/ErrorLoadIcon.svg";
import { CenterStack, StyledA, StyledElement } from "../../../../Styles/GlobalStyles";
import ContactSupport from "../../../../components/shared/ContactSupport/ContactSupport";
import Button from "../../../../components/Button/Button";

export const NetworkStatusErrorComponent = ({ handleRefresh, errorMessage, buttonTitle }: any) => {
  return (
    <CenterStack my={4} gap={2}>
      <Typography textAlign={"center"} color={"#DF6B00"} fontSize={{ xs: "13px", md: "16px" }}>
        {errorMessage}
      </Typography>
      <CenterStack direction={"row"} gap={1} flexWrap={"wrap"}>
        <Typography fontSize={"14px"}> You can</Typography>
        <ContactSupport text="Chat with an agent" />

        <Typography fontSize={"14px"}> or call </Typography>
        <StyledA href="tel:1-866-699-4759">1-866-MYZIPLY</StyledA>
        <Typography fontSize={"14px"}> for further research on your issue.</Typography>
      </CenterStack>

      <Button title={buttonTitle} type="root" onClick={handleRefresh} />
    </CenterStack>
  );
};
